
<app-header [showLogout]="false" [showTitle]="false" [showBack]="true"></app-header>
<div class="report-content" *ngIf="reportJSON">
    <div class="text-center">
          <img src="./assets/images/logo/GOINGPLACES_BW-HR.jpg" height="120px">
          <h2 class="heading-title">{{reportJSON.title}}</h2>
    </div>

    <div class="row">
        <div class="col-sm-12 report-email-div">
            <button type="button" class="btn report-btn" style="width: 120px;margin-right: 2px" (click)="download()">{{reportGeneralInfoJSON.downloadButtonTitle}}</button>
            <button class="btn report-btn" (click)="showEmailBox = !showEmailBox">{{reportGeneralInfoJSON.emailButtonTitle}}</button><br><br>
            <p style="color: red;" *ngIf="emailStatus != ''">{{emailStatus}}</p>
            <p style="font-size: small;margin-top: -10px;">{{reportGeneralInfoJSON.downloadWarning}}</p>
            <span style="font-size: small;padding-right: 12px;">Choose a language</span>
            <select class="form-select form-select-sm" [(ngModel)]="reportLanguage" (ngModelChange)="switchReportLanguage()">
                <option value="English">English</option>
                <option value="Afrikaans">Afrikaans</option>
                <option value="Xhosa">Xhosa</option>
                <option value="Zulu">Zulu</option>
              </select>
        </div>
    </div>
    <!-- <div class="loading" *ngIf="bShowLoader"></div> -->
    <div *ngIf="showEmailBox" style="margin-top: 10px;margin-bottom: 14px;">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{reportGeneralInfoJSON.sendPDFReport}}</h4>
                <i class="fa fa-times pointer" title="Close" (click)="showEmailBox=false"></i>
            </div>
            <div class="modal-body">
                <table class="report-table" width="100%" border="0">
                    <tr>
                        <th width="1%">{{reportGeneralInfoJSON.to}}:</th>
                        <td style="text-align: left; width: 100%"><input type="email" class="form-control form-control-sm" placeholder="to email address" [(ngModel)]="toAddress"></td>
                    </tr>
                    <tr>
                        <th width="1%">{{reportGeneralInfoJSON.cc}}:</th>
                        <td style="text-align: left; width: 100%"><input type="email" class="form-control form-control-sm" placeholder="cc email address" [(ngModel)]="ccAddress"></td>
                    </tr>
                    <tr>
                        <th width="1%">{{reportGeneralInfoJSON.participantName}}:</th>
                        <td style="text-align: left; width: 100%"><input type="email" class="form-control form-control-sm" placeholder="Participant name" [(ngModel)]="participantName"></td>
                    </tr>
                </table>
               
            </div>
            <div class="modal-footer">
                <button type="button" class="btn report-btn" style="width: 120px;" (click)="showEmailBox=false">{{reportGeneralInfoJSON.cancelButtonTitle}}</button>
                <button type="button" class="btn report-btn" style="width: 120px;" (click)="sendEmail()" >{{reportGeneralInfoJSON.sendButtonTitle}}</button>
            </div> 
        </div>
    </div> 

    <div class="row" style="padding-top: 12px;">
        <div class="col-xs-3 col-sm-3"><b>{{reportGeneralInfoJSON.referenceNumber}}:</b></div>
        <div class="col-xs-5 col-sm-5"><small>{{token}}</small></div>
        <div class="col-xs-1 col-sm-1"><b>{{reportGeneralInfoJSON.date}}:</b></div>
        <div class="col-xs-2 col-sm-2"><small>{{today}}</small></div>
    </div>

    <div *ngFor="let section of reportJSON.sections">

        <H3 *ngIf="section._include">{{section.title}}</H3>
        <p *ngIf="section._include"><b>{{section.description}}</b></p>
        
        <div class="row pb-5" *ngIf="section._name == constants.INSIGHTACTIVEDRIVER || section._name == constants.INSIGHTNONACTIVEDRIVER" >
            <div class="col-xs-12 col-sm-12"><h4><b>{{section['secondDescription']}}</b></h4></div>
        </div>

        <div class="row" *ngFor="let item of section.elements">
            <div class="col-xs-9 col-sm-9">{{item['title']}}</div>
            <div class="col-xs-1 col-sm-1 score-text" *ngIf="item['scoreAnswer']">{{item['scoreAnswer']}}</div>
            <div class="col-xs-1 col-sm-1 score-text" *ngIf="!item['scoreAnswer']">{{item['score']}}/{{item['scoreOutOf']}}</div>
        </div>
        <div class="row pb-5" *ngIf="section._name == constants.DRIVINGFITNESS" >
            <div class="col-xs-12 col-sm-12">{{section['secondDescription']}}</div>
        </div>
        
        <div class="row" *ngIf="section._include">
            <div class="col-xs-9 col-sm-9 bold"><b>{{section.totalName}}</b></div>
            <div class="col-xs-1 col-sm-1 bold score-text">{{section.totalScore}}/{{section.scoreOutOf}}</div>
            <div class="col-xs-1 col-sm-1 bold score-text">{{section.scorePerc}}%</div>
        </div>
        <div *ngIf="section._name == 'driving_fitness_risk_profile'" 
            style="background-color: #e0e0e0; margin-top: 18px; padding-top: 1px;padding-bottom: 1px; padding-left:6px; padding-right: 6px;">
            <h4><b>{{section.title}}</b></h4>
            <p><b>{{section.description}}</b></p>
            <p [innerHTML]="section.secondDescription"></p>
            <p [innerHTML]="section.thirdDescription"></p>
            <p [innerHTML]="section.fourthDescription"></p>
        </div>

        <div class="row" *ngIf="section._name == constants.INSIGHTACTIVEDRIVER || section._name == constants.INSIGHTNONACTIVEDRIVER">
            <br>
            <div class="col-xs-12 col-sm-12">
            {{section.footerA}} 
            </div>
        </div>
        <div class="row" class="row" *ngIf="section._name == constants.INSIGHTACTIVEDRIVER || section._name == constants.INSIGHTNONACTIVEDRIVER">
            <br>
            <div class="col-xs-12 col-sm-12">
                {{section.footerB}} 
            </div>
        </div>

        <div class="row" *ngIf="section._name == constants.INSIGHTACTIVEDRIVER || section._name == constants.INSIGHTNONACTIVEDRIVER">
            <br>
            <div class="col-xs-8">
                <div class="chart-wrapper">
                    <canvas baseChart 
                    id="selfChart"
                    [datasets]="barChartData"
                    [labels]="barChartLabels"
                    [options]="barChartOptions"
                    [plugins]="barChartPlugins"
                    [legend]="barChartLegend"
                    [chartType]="barChartType">
                </canvas>
                </div>
            </div>
            <div class="col-xs-4 formula">
                <h3>{{column1Perc}} % - {{column2Perc}} % = {{totalDiff}} %</h3>
            </div>
        </div>

        <div class="row" *ngIf="section._name == constants.INSIGHTACTIVEDRIVER || section._name == constants.INSIGHTNONACTIVEDRIVER">
            <div class="col-xs-12 col-sm-12" style="background-color: #e0e0e0; margin-top: 20px; margin-left: 16px; margin-right: 40px; padding-bottom: 10px; width: 97%;">
                <H4><b>{{reportInsightJSON.title}}</b></H4>
                <table border="1" width="100%">
                    <tr>
                        <td width="10%" style="text-align: center;"><b>{{reportInsightJSON.columnA.title}}</b></td>
                        <td width="10%" style="text-align: center;"><b>{{reportInsightJSON.columnB.title}}</b></td>
                    </tr>
                    <tr>
                        <td width="10%" style="text-align: center; padding: 3px;" [innerHTML]="reportInsightJSON.columnA.name"></td>
                        <td width="10%" style="text-align: center; padding: 3px;" [innerHTML]="reportInsightJSON.columnB.name"></td>
                    </tr>
                    <tr valign="top">
                        <td width="10%" style="text-align: left; padding: 3px;">
                            <ul>
                                <li>{{reportInsightJSON.columnA.listItemA}}</li>
                                <li>{{reportInsightJSON.columnA.listItemB}}</li>
                            </ul>
                        </td>
                        <td width="10%" style="text-align: left; padding: 3px;">
                            <ul>
                                <li>{{reportInsightJSON.columnB.listItemA}}</li>
                                <li>{{reportInsightJSON.columnB.listItemB}}</li>
                            </ul>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div *ngIf="reportName === constants.CODRIS && section._name === constants.COMMUNITYMOBILITYRISKPROFILE">
            <div style="background-color: #e0e0e0;margin-top: 8px; padding-top: 1px;padding-bottom: 1px; padding-left:6px;padding-right: 6px;">
                <h4><b>{{section.title}}</b></h4>
                <p [innerHTML]="section.secondDescription"></p>
                <p [innerHTML]="section.thirdDescription"></p>
                <p [innerHTML]="section.fourthDescription"></p>
            </div>
        </div>

    </div>

    <div style="height:200px;"></div>
</div>
<app-footer></app-footer>

