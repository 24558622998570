import { CalculationService } from './../../calculation.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { ActivatedRoute } from '@angular/router';
import { IreportItem } from '../results/results.component';
import html2canvas from 'html2canvas';
import { AssessmentService } from '../_services/assessment.service';
import { ApiService } from 'src/app/api.service';
import { AppConstants } from 'src/app/app.constants';


export interface iReportItem {
  itemName: string;
  scoreOutOf: number;
  score: number;
}

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ReportComponent implements OnInit {

  surveyResults: any = {};
  surveyTime = 0;
  token = '';
  today = new Date().toISOString().slice(0, 10);

  // New json file implementation values
  reportJSON: any = {};
  reportInsightJSON: any = {};
  reportGeneralInfoJSON: any = {};
  reportAnswers: any = {};
  reportLanguage = this.constants.ENGLISH;
  reportName = this.constants.CODRIS;
  
  showEmailBox = false;
  toAddress = '';
  ccAddress = '';
  downloadReport = false;
  emailStatus = '';

  selfAssessmentData: any[] = [];
  bShowLoader = false;

  //
  // TRIP PLANNING
  //

  placeOrientation = 0;
  timeHiddenDateTime: Date = new Date();
  timeYear = 0;
  timeMonth = 0;
  timeWeekDay = 0;
  timeDay = 0;
  timeNoLookHour = 0;
  timeNoLookMinute = 0;
  timeLookHour = 0;
  timeLookMinute = 0;
  timeCalculation = 0;
  totalTimeOrientation = 0;
  percTimeOrientation = '0';
  totalDirections = 0;
  totalMoneyManagement = 0;
  totalFuelCalculation = 0;
  countryName = '';
  regionName = '';
  tripPlanningItems: iReportItem[] = [];
  communityItems: iReportItem[] = [];

  //
  // DRIVING ABILITY
  //

  totalIdentifySigns = 0;
  totalMemory = 0;
  totalMemory2 = 0;
  totalVisualMemory = 0;
  totalAuditoryMemory = 0;
  totalMemoryDirections = 0;
  totalFindRoadTrafficSign = 0;
  totalRoadRules = 0;
  totalDrivingManoeuvre = 0;
  totalDrivingManoeuvreFunc = 0;
  intersectionA = '';
  intersectionB = '';
  intersectionC = '';
  intersectionD = '';
  totalIntersections = 0;
  arrMemorySigns = Array();
  arrRoadSignClicks = Array();
  arrCarInteriorClicks = Array();
  arrRoadSideClicks = Array();
  landmarkClick1 = '';
  landmarkClick2 = '';
  landmarkClick3 = '';
  totalRoadSideFactors = 0;
  totalTaxi = 0;
  totalTripInstructions = 0;
  drivingAbilityItems: iReportItem[] = [];

  totalSelfAssessmentData = 0;
  percSelfAssessmentData = '0';
  totalTripPlanning = 0;
  totalTripPlanningOutOf = 0;
  percTripPlanning = '0';
  totalDrivingAbility = 0;
  totalDrivingAbilityOutOf = 0;
  percDrivingAbility = '0';
  percDrivingAbilityOutOf = 0;
  totalDrivingFitness = 0;
  totalDrivingFitnessOutOf = 0;
  percDrivingFitness = '0';
  totalCommunityMobility = 0;
  totalCommunityMobilityOutOf = 0;
  percCommunityMobility = '0';
  totalTransportation = 0;
  percTransportation = '0';
  totalInsight = 0;
  totalInsightOutOf = 0;

  landmark = 0;

  totalDiff = 0;

  // chart
  chartURI = "";
  column1Perc = 50;
  column2Perc = 50;
  barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
          ticks: {
              beginAtZero: true,
              fontSize: 14,
          }
      }],
      xAxes: [{
        ticks: {
            beginAtZero: true,
            fontSize: 16,
        }
    }]
    },
    tooltips: {
      callbacks: {
          // tslint:disable-next-line:typedef
          label: function(tooltipItem) {
              return ' ' + Number(tooltipItem.yLabel) + ' %';
          }
      }
  },
  plugins: {
      legend: {
          labels: {
              // This more specific font property overrides the global property
              font: {
                  size: 14,
                  family: '\'Open Sans\''
              }
          }
      }
  }

  };
  // tslint:disable-next-line:ban-types
  barChartLabels: any[] = ['Self Assessment', 'Driving Fitness'];
  barChartType: ChartType = 'bar';
  barChartLegend = false;
  barChartPlugins = [];
  barChartData: ChartDataSets[] = [];

  dbResult: IreportItem[] = [];
  
  participantName = '';

  constructor(private calcService: CalculationService, 
              private activatedRoute: ActivatedRoute,
              private assessmentService: AssessmentService,
              private apiService: ApiService,
              public constants: AppConstants) {
  }

  ngOnInit(): void {
    this.resetAllScores();
    // get token and fetch data from database
    const token = this.activatedRoute.snapshot.paramMap.get('id')?.toString();
    this.getTokenData(token!);
    this.bShowLoader = true;
    this.reportLanguage = localStorage.getItem('assessmentLanguage')?.toString()!;
    console.log('language', this.reportLanguage);
  }

  getTokenData(token: string): void {
    this.assessmentService.getTokenData(token).subscribe(
      data => {
        this.reportName = data.assessmentName;
        this.participantName = data.participantName;
        this.getAssessmentData(token);

        this.reportJSON = this.apiService.getReportAssessment(this.reportLanguage, this.reportName);
        this.reportInsightJSON = this.apiService.getReportInsightInterpretation(this.reportLanguage);
        this.reportGeneralInfoJSON = this.apiService.getReportGeneralInformation(this.reportLanguage);
      },
      err => {
       console.log('error fetching token data', err);
      }
    );
 }

 getAssessmentData(token: string): void {
  this.assessmentService.getAssessmentData(token).subscribe(
    data => {

      if (data && data.length > 0) {
        this.dbResult = data;
        const replacedData = this.dbResult[0].assessmentData.replace(/\r?\n|\r/g, '');
        const assessmentData = JSON.parse(replacedData);
        this.today = this.dbResult[0].dDateCreated.split(' ')[0];
        this.token = this.dbResult[0].sToken;
        this.reportAnswers = assessmentData;
        this.formatData();
      }
    },
    err => {
     console.log('error fetching assessment data', err);
    }
  );
 }

  formatData(): void {

    this.bShowLoader = false;

    this.arrCarInteriorClicks = this.reportAnswers.CarInteriorClicks;
    this.arrRoadSideClicks = this.reportAnswers.RoadSideClicks;
    this.arrRoadSignClicks = this.reportAnswers.RoadSignClicks;

    this.landmarkClick1 = this.reportAnswers.LANDMARK_q1;
    this.landmarkClick2 = this.reportAnswers.LANDMARK_q2;
    this.landmarkClick3 = this.reportAnswers.LANDMARK_q3;

    //
    // TRIP PLANNING
    //
    if (this.reportAnswers.ORIENTATION_TIME_hiddenDateTime) {
      this.timeHiddenDateTime = new Date(this.reportAnswers.ORIENTATION_TIME_hiddenDateTime);
    } 
    if (this.reportAnswers.ORIENTATION_TIME_month) {
      this.timeMonth = this.reportAnswers.ORIENTATION_TIME_month;
    }
    if (this.reportAnswers.ORIENTATION_TIME_year) {
      this.timeYear = this.reportAnswers.ORIENTATION_TIME_year;
    }
    if (this.reportAnswers.ORIENTATION_TIME_week_day) {
      this.timeWeekDay = this.reportAnswers.ORIENTATION_TIME_week_day;
    }
    if (this.reportAnswers.ORIENTATION_TIME_day) {
      this.timeDay = this.reportAnswers.ORIENTATION_TIME_day;
    }
    if (this.reportAnswers.ORIENTATION_TIME_no_look_now_hour) {
      this.timeNoLookHour = this.reportAnswers.ORIENTATION_TIME_no_look_now_hour;
    }
    if (this.reportAnswers.ORIENTATION_TIME_no_look_now_minute) {
      this.timeNoLookMinute = this.reportAnswers.ORIENTATION_TIME_no_look_now_minute;
    }
    if (this.reportAnswers.ORIENTATION_TIME_look_now_hour) {
      this.timeLookHour = this.reportAnswers.ORIENTATION_TIME_look_now_hour;
    }
    if (this.reportAnswers.ORIENTATION_TIME_look_now_minute) {
      this.timeLookMinute = this.reportAnswers.ORIENTATION_TIME_look_now_minute;
    } 
    if (this.reportAnswers.TIME_calculation) {
      this.timeCalculation = this.calcService.timeCalculationHandler(this.reportAnswers.TIME_calculation);
    } 
    if (this.reportAnswers.DIRECTION1) {
      this.totalDirections = this.totalDirections + this.calcService.directionHandler('DIRECTION1', this.reportAnswers.DIRECTION1);
    }
    if (this.reportAnswers.DIRECTION2) {
      this.totalDirections = this.totalDirections + this.calcService.directionHandler('DIRECTION2', this.reportAnswers.DIRECTION2);
    }
    if (this.reportAnswers.DIRECTION3) {
      this.totalDirections = this.totalDirections + this.calcService.directionHandler('DIRECTION3', this.reportAnswers.DIRECTION3);
    }
    if (this.reportAnswers.DIRECTION4) {
      this.totalDirections = this.totalDirections + this.calcService.directionHandler('DIRECTION4', this.reportAnswers.DIRECTION4);
    }
    if (this.reportAnswers.MONEY_MANAGEMENT_parking) {
      this.totalMoneyManagement = this.totalMoneyManagement + this.calcService.moneyParkingHandler(this.reportAnswers.MONEY_MANAGEMENT_parking);
    } 
    if (this.reportAnswers.MONEY_MANAGEMENT_change) {
      this.totalMoneyManagement = this.totalMoneyManagement + this.calcService.moneyChangeHandler(this.reportAnswers.MONEY_MANAGEMENT_change);
    }
    if (this.reportAnswers.FUEL_CALCULATION) {
      this.totalFuelCalculation = this.totalFuelCalculation + this.calcService.fuelCalculationHandler(this.reportAnswers.FUEL_CALCULATION);
    }

    //
    // DRIVING ABILITY
    //

    if (this.reportAnswers.IDENTIFY_PEDESTRIAN) {
      // tslint:disable-next-line:max-line-length
      this.totalIdentifySigns = this.totalIdentifySigns + this.calcService.identifySignHandler('IDENTIFY_PEDESTRIAN', this.reportAnswers.IDENTIFY_PEDESTRIAN);
    } 
    if (this.reportAnswers.IDENTIFY_STOP) {
      // tslint:disable-next-line:max-line-length
      this.totalIdentifySigns = this.totalIdentifySigns + this.calcService.identifySignHandler('IDENTIFY_STOP', this.reportAnswers.IDENTIFY_STOP);
    }
    if (this.reportAnswers.IDENTIFY_CIRCLE) {
      // tslint:disable-next-line:max-line-length
      this.totalIdentifySigns = this.totalIdentifySigns + this.calcService.identifySignHandler('IDENTIFY_CIRCLE', this.reportAnswers.IDENTIFY_CIRCLE);
    }
    if (this.reportAnswers.IDENTIFY_RIGHT_TURN) {
      // tslint:disable-next-line:max-line-length
      this.totalIdentifySigns = this.totalIdentifySigns + this.calcService.identifySignHandler('IDENTIFY_RIGHT_TURN', this.reportAnswers.IDENTIFY_RIGHT_TURN);
    }
    if (this.reportAnswers.MEMORY1) {
      this.arrMemorySigns = this.reportAnswers.MEMORY1;
    }
    if (this.reportAnswers.MEMORY2_DIRECTION1) {
      this.totalMemory2 = this.totalMemory2 + this.calcService.memory2Handler('MEMORY2_DIRECTION1', this.reportAnswers.MEMORY2_DIRECTION1);
    }
    if (this.reportAnswers.MEMORY2_DIRECTION2) {
      this.totalMemory2 = this.totalMemory2 + this.calcService.memory2Handler('MEMORY2_DIRECTION2', this.reportAnswers.MEMORY2_DIRECTION2);
    }
    if (this.reportAnswers.MEMORY2_DIRECTION3) {
      this.totalMemory2 = this.totalMemory2 + this.calcService.memory2Handler('MEMORY2_DIRECTION3', this.reportAnswers.MEMORY2_DIRECTION3);
    }
    if (this.reportAnswers.MEMORY2_DIRECTION4) {
      this.totalMemory2 = this.totalMemory2 + this.calcService.memory2Handler('MEMORY2_DIRECTION4', this.reportAnswers.MEMORY2_DIRECTION4);
    }
    if (this.reportAnswers.INTERSECTIONS1A) {
      this.intersectionA = this.intersectionA.concat(this.reportAnswers.INTERSECTIONS1A);
    }
    if (this.reportAnswers.INTERSECTIONS1B) {
      this.intersectionA = this.intersectionA.concat(this.reportAnswers.INTERSECTIONS1B);
    }
    if (this.reportAnswers.INTERSECTIONS2A) {
      this.intersectionB = this.intersectionB.concat(this.reportAnswers.INTERSECTIONS2A);
    }
    if (this.reportAnswers.INTERSECTIONS2B) {
      this.intersectionB = this.intersectionB.concat(this.reportAnswers.INTERSECTIONS2B);
    }
    if (this.reportAnswers.INTERSECTIONS2C) {
      this.intersectionB = this.intersectionB.concat(this.reportAnswers.INTERSECTIONS2C);
    }
    if (this.reportAnswers.INTERSECTIONS3A) {
      this.intersectionC = this.intersectionC.concat(this.reportAnswers.INTERSECTIONS3A);
    }
    if (this.reportAnswers.INTERSECTIONS3B) {
      this.intersectionC = this.intersectionC.concat(this.reportAnswers.INTERSECTIONS3B);
    }
    if (this.reportAnswers.INTERSECTIONS3C) {
      this.intersectionC = this.intersectionC.concat(this.reportAnswers.INTERSECTIONS3C);
    }
    if (this.reportAnswers.INTERSECTIONS4A) {
      this.intersectionD = this.intersectionD.concat(this.reportAnswers.INTERSECTIONS4A);
    }
    if (this.reportAnswers.INTERSECTIONS4B) {
      this.intersectionD = this.intersectionD.concat(this.reportAnswers.INTERSECTIONS4B);
    }
    if (this.reportAnswers.ROADRULES) {
      this.totalRoadRules =  this.calcService.roadRulesHandler(Object.entries(this.reportAnswers.ROADRULES));
    }
    if (this.reportAnswers.ROADSIDEFACTORS_q1) {
      if (this.reportAnswers.ROADSIDEFACTORS_q1 === 'One way') {
        this.totalRoadSideFactors = this.totalRoadSideFactors + 1;
      }
    } 
    if (this.reportAnswers.DRIVING_MANOEUVRE_triangular) {
      if (this.reportAnswers.DRIVING_MANOEUVRE_triangular === 'One road lane is merging into another.') {
        this.totalDrivingManoeuvre = this.totalDrivingManoeuvre + 1;
      }
    }
    if (this.reportAnswers.ORDERTAXI1) {
      this.totalTaxi = this.totalTaxi + this.calcService.orderTaxiHander('ORDERTAXI1', this.reportAnswers.ORDERTAXI1);
    }
    if (this.reportAnswers.ORDERTAXI2) {
      this.totalTaxi = this.totalTaxi + this.calcService.orderTaxiHander('ORDERTAXI2', this.reportAnswers.ORDERTAXI2);
    }
    if (this.reportAnswers.ORDERTAXI3) {
      this.totalTaxi = this.totalTaxi + this.calcService.orderTaxiHander('ORDERTAXI3', this.reportAnswers.ORDERTAXI3);
    }
    if (this.reportAnswers.ORDERTAXI4) {
      this.totalTaxi = this.totalTaxi + this.calcService.orderTaxiHander('ORDERTAXI4', this.reportAnswers.ORDERTAXI4);
    } 
    if (this.reportAnswers.LANDMARK_q1) {
      this.landmark = this.landmark + this.calcService.landmarkHandler('LANDMARK_q1', this.reportAnswers.LANDMARK_q1);
    }
    if (this.reportAnswers.LANDMARK_q2) {
      this.landmark = this.landmark + this.calcService.landmarkHandler('LANDMARK_q2', this.reportAnswers.LANDMARK_q2);
    }
    if (this.reportAnswers.LANDMARK_q3) {
      this.landmark = this.landmark + this.calcService.landmarkHandler('LANDMARK_q3', this.reportAnswers.LANDMARK_q3);
    }

    // trip instructions - codris employer
    if (this.reportAnswers.p18q6) {
      if (this.reportAnswers.p18q6 === '23') {
        this.totalTripInstructions++;
      }
    }
    if (this.reportAnswers.p18q7) {
      if (this.reportAnswers.p18q7 === '10 Boxes 4 Bags') {
        this.totalTripInstructions++;
      }
    }
    if (this.reportAnswers.p18q8) {
      if (this.reportAnswers.p18q8 === 'Alpha') {
        this.totalTripInstructions++;
      }
    }
    if (this.reportAnswers.p18q9) {
      if (this.reportAnswers.p18q9 === 'No') {
        this.totalTripInstructions++;
      }
    }


    this.totalVisualMemory = this.calcService.getVisualMemory(this.arrMemorySigns);
    this.totalAuditoryMemory = this.totalMemory2;
    this.totalMemory = this.calcService.identifyMemorySignsHandler(this.arrMemorySigns, this.totalMemory2);
    this.totalTimeOrientation =  this.calcService.timeOrientationHandler(this.timeYear,
                                                                        this.timeHiddenDateTime,
                                                                        this.timeNoLookHour,
                                                                        this.timeNoLookMinute,
                                                                        this.timeLookHour,
                                                                        this.timeLookMinute,
                                                                        this.timeMonth,
                                                                        this.timeWeekDay,
                                                                        this.timeDay,
                                                                        '');

    
    this.totalFindRoadTrafficSign = this.calcService.findRoadTrafficSignHandler(this.arrRoadSignClicks);
    this.totalRoadSideFactors = this.totalRoadSideFactors + this.calcService.roadSideFactorsHandler(this.arrRoadSideClicks);
    this.totalDrivingManoeuvre = this.totalDrivingManoeuvre + this.calcService.drivingManoeuvreHandler(this.arrCarInteriorClicks);
    this.totalIntersections = this.calcService.intersectionHandler(this.intersectionA, this.intersectionB, this.intersectionC, this.intersectionD);
    
    this.setTripPlanning();
    this.setDrivingAbility();
    this.setDrivingFitness();
    this.setInsight();
    if (this.reportName == this.constants.CODRIS) {
      this.setCommunityMobility();
    }
    this.setTransportation();
  }

  setTripPlanning(): void {
    var section = this.reportJSON.sections.find((x: { _name: string; }) => x._name == this.constants.TRIPPLANNING);

    for (var i=0; i < section.elements.length; i++) {
      this.totalTripPlanningOutOf += section.elements[i].scoreOutOf;
      if (section.elements[i]._name === this.constants.ORIENTATIONTOTIME) {
        section.elements[i].score = this.totalTimeOrientation;
      } else if (section.elements[i]._name === this.constants.TIMECALCULATION) {
        section.elements[i].score = this.timeCalculation;
      } else if (section.elements[i]._name === this.constants.MONEYMANAGEMENT) {
        section.elements[i].score = this.totalMoneyManagement;
      } else if (section.elements[i]._name === this.constants.FUELCALCULATION) {
        section.elements[i].score = this.totalFuelCalculation;
      } else if (section.elements[i]._name === this.constants.DIRECTIONS) {
        section.elements[i].score = this.totalDirections;
      } else if (section.elements[i]._name === this.constants.ORDERATAXI) {
        section.elements[i].score = this.totalTaxi;
      } else if (section.elements[i]._name === this.constants.TRIPINSTRUCTIONS) { // employee
        section.elements[i].score = this.totalTripInstructions;
      }
    }

    this.totalTripPlanning += this.totalTimeOrientation;
    this.totalTripPlanning += this.timeCalculation;
    this.totalTripPlanning += this.totalDirections;
    this.totalTripPlanning += this.totalMoneyManagement;
    this.totalTripPlanning += this.totalFuelCalculation;
    this.totalTripPlanning += this.totalTaxi;
    this.totalTripPlanning += this.totalTripInstructions;
    this.percTripPlanning = (this.totalTripPlanning / this.totalTripPlanningOutOf * 100).toFixed(0);

    section.scoreOutOf = this.totalTripPlanningOutOf;
    section.totalScore = this.totalTripPlanning;
    section.scorePerc = this.percTripPlanning;
  }

  setDrivingAbility(): void {
    var section = this.reportJSON.sections.find((x: { _name: string; }) => x._name == this.constants.DRIVINGABILITY);

    for (var i=0; i < section.elements.length; i++) {
      this.totalDrivingAbilityOutOf += section.elements[i].scoreOutOf;
      if (section.elements[i]._name === this.constants.IDENTIFYTRAFFICSINGS) {
        section.elements[i].score = this.totalIdentifySigns;
      } else if (section.elements[i]._name === this.constants.VISUALMEMORY) {
        section.elements[i].score = this.totalVisualMemory;
      } else if (section.elements[i]._name === this.constants.AUDITORYMEMORY) {
        section.elements[i].score = this.totalAuditoryMemory;
      } else if (section.elements[i]._name === this.constants.FINDTHETRAFFICSIGN) {
        section.elements[i].score = this.totalFindRoadTrafficSign;
      } else if (section.elements[i]._name === this.constants.INTERSECTIONS) {
        section.elements[i].score = this.totalIntersections;
      } else if (section.elements[i]._name === this.constants.LANDMARKNAVIGATION) {
        section.elements[i].score = this.landmark;
      } else if (section.elements[i]._name === this.constants.ROADRULES) {
        section.elements[i].score = this.totalRoadRules;
      } else if (section.elements[i]._name === this.constants.ROADSIDEFACTORS) {
        section.elements[i].score = this.totalRoadSideFactors;
      } else if (section.elements[i]._name === this.constants.DRIVINGMANOEUVRE) {
        section.elements[i].score = this.totalDrivingManoeuvre;
      }
    }

    this.totalDrivingAbility += this.totalIdentifySigns;
    this.totalDrivingAbility += this.totalVisualMemory;
    this.totalDrivingAbility += this.totalAuditoryMemory;
    this.totalDrivingAbility += this.totalFindRoadTrafficSign;
    this.totalDrivingAbility += this.totalIntersections;
    this.totalDrivingAbility += this.landmark;
    this.totalDrivingAbility += this.totalRoadRules;
    this.totalDrivingAbility += this.totalRoadSideFactors;
    this.totalDrivingAbility += this.totalDrivingManoeuvre;
    this.percDrivingAbility = (this.totalDrivingAbility / this.totalDrivingAbilityOutOf * 100).toFixed(0);

    section.scoreOutOf = this.totalDrivingAbilityOutOf;
    section.totalScore = this.totalDrivingAbility;
    section.scorePerc = this.percDrivingAbility;

    // console.log('setDrivingAbility', section);
  }

  setDrivingFitness(): void {
    var section = this.reportJSON.sections.find((x: { _name: string; }) => x._name == this.constants.DRIVINGFITNESS);

    this.totalDrivingFitness += this.totalTripPlanning;
    this.totalDrivingFitness += this.totalDrivingAbility;
    this.percDrivingFitness = (this.totalDrivingFitness / (this.totalTripPlanningOutOf + this.totalDrivingAbilityOutOf) * 100).toFixed(0);


    section.scoreOutOf = this.totalTripPlanningOutOf + this.totalDrivingAbilityOutOf;
    section.totalScore = this.totalTripPlanning + this.totalDrivingAbility;
    section.scorePerc = this.percDrivingFitness;

    this.column2Perc = +this.percDrivingFitness;
  }

  setInsight(): void {
    var section = null;
    this.barChartLabels = [this.reportGeneralInfoJSON.graphColumnATitle, this.reportGeneralInfoJSON.graphColumnBTitle];

    var activeIndex = this.reportJSON.sections.findIndex((x: { _name: string; }) => x._name == this.constants.INSIGHTACTIVEDRIVER);
    var nonActiveIndex = this.reportJSON.sections.findIndex((x: { _name: string; }) => x._name == this.constants.INSIGHTNONACTIVEDRIVER);
    
    if (this.reportAnswers.SELF_ASSESSMENT_active_driver) {
      section = this.reportJSON.sections.find((x: { _name: string; }) => x._name == this.constants.INSIGHTACTIVEDRIVER);
      // remove non-active driver items
      if (nonActiveIndex != -1) {
        this.reportJSON.sections.splice(nonActiveIndex,1);
      }
      const answers = Object.entries(this.reportAnswers.SELF_ASSESSMENT_active_driver);
      for (let i = 0; i < answers.length; i++) {
        var question = answers[i][0].toString().toLocaleLowerCase().replace(/ /g,"_");
        var answer = answers[i][1];
        var element = section.elements.find((x: { _name: string; }) => x._name == question);
        
        this.totalInsightOutOf += element.scoreOutOf;
        if (answer === 1) {
          element.scoreAnswer = this.reportGeneralInfoJSON.sometimes;
          element.score = 1;
          this.totalInsight += 1;
        } else if (answer === 2) {
          element.scoreAnswer = this.reportGeneralInfoJSON.always;
          element.score = 0;
        } else {
          element.scoreAnswer = this.reportGeneralInfoJSON.never;
          element.score = 2;
          this.totalInsight += 2;
        }
      }

    } else if (this.reportAnswers.SELF_ASSESSMENT_non_active_driver){

      section = this.reportJSON.sections.find((x: { _name: string; }) => x._name == this.constants.INSIGHTNONACTIVEDRIVER);
      // remove active driver items
      
      if (activeIndex != -1) {
        this.reportJSON.sections.splice(activeIndex,1);
      }
      const answers = Object.entries(this.reportAnswers.SELF_ASSESSMENT_non_active_driver);
      for (let i = 0; i < answers.length; i++) {
        var question = answers[i][0].toString().toLocaleLowerCase().replace(/ /g,"_");
        var answer = answers[i][1];
        var element = section.elements.find((x: { _name: string; }) => x._name == question);
        
        this.totalInsightOutOf += element.scoreOutOf;
        if (answer === 1) {
          element.scoreAnswer = this.reportGeneralInfoJSON.maybe;
          element.score = 1;
          this.totalInsight += 1;
        } else if (answer === 2) {
          element.scoreAnswer = this.reportGeneralInfoJSON.yes;
          element.score = 0;
        } else {
          element.scoreAnswer = this.reportGeneralInfoJSON.no;
          element.score = 2;
          this.totalInsight += 2;
        }
      }
    } 

    if (section && this.reportAnswers.SELF_ASSESSMENT_performance) {
      const answers = Object.entries(this.reportAnswers.SELF_ASSESSMENT_performance);
      for (let i = 0; i < answers.length; i++) {
        var question = answers[i][0].toString().toLocaleLowerCase().replace(/ /g,"_");
        var answer = answers[i][1];
        var element = section.elements.find((x: { _name: string; }) => x._name == question);
        this.totalInsightOutOf += element.scoreOutOf;
        if (answer === '0') {
          element.scoreAnswer = this.reportGeneralInfoJSON.poor;
          element.score = 0;
        } else if (answer === '1') {
          element.scoreAnswer = this.reportGeneralInfoJSON.average;
          element.score = 1;
          this.totalInsight += 1;
        } else if (answer === '2') {
          element.scoreAnswer = this.reportGeneralInfoJSON.great;
          element.score = 2;
          this.totalInsight += 2;
        }
      }
    }

    
    if (section) {
      section.scoreOutOf = this.totalInsightOutOf;
      section.totalScore = this.totalInsight;
      section.scorePerc = (this.totalInsight / this.totalInsightOutOf * 100).toFixed(0);
    }

    this.column1Perc = this.totalInsightOutOf === 0 ? 0 : +(this.totalInsight / this.totalInsightOutOf * 100).toFixed(0);
    this.totalDiff = this.column1Perc - this.column2Perc;

    this.barChartData = [
      { data: [this.column1Perc, this.column2Perc, 100],
        label: '',
        backgroundColor: ['#1ab394', '#63666A'],
        hoverBackgroundColor: ['#1ab394', '#63666A'],
        borderColor: 'transparent'
      }
    ];
  }

  setCommunityMobility(): void {
    var section = this.reportJSON.sections.find((x: { _name: string; }) => x._name == this.constants.COMMUNITYMOBILITY);

    for (var i=0; i < section.elements.length; i++) {
      this.totalCommunityMobilityOutOf += section.elements[i].scoreOutOf;
      if (section.elements[i]._name === this.constants.ORIENTATIONTOTIME) {
        section.elements[i].score = this.totalTimeOrientation;
      } else if(section.elements[i]._name === this.constants.TIMECALCULATION) {
        section.elements[i].score = this.timeCalculation;
      } else if(section.elements[i]._name === this.constants.MONEYMANAGEMENT) {
        section.elements[i].score = this.totalMoneyManagement;
      } else if(section.elements[i]._name === this.constants.FUELCALCULATION) {
        section.elements[i].score = this.totalFuelCalculation;
      } else if(section.elements[i]._name === this.constants.DIRECTIONS) {
        section.elements[i].score = this.totalDirections;
      } else if(section.elements[i]._name === this.constants.IDENTIFYTRAFFICSINGS) {
        section.elements[i].score = this.totalIdentifySigns;
      } else if(section.elements[i]._name === this.constants.MEMORY) {
        section.elements[i].score = this.totalMemory;
      } else if(section.elements[i]._name === this.constants.FINDTHETRAFFICSIGN) {
        section.elements[i].score = this.totalFindRoadTrafficSign;
      } else if(section.elements[i]._name === this.constants.LANDMARKNAVIGATION) {
        section.elements[i].score = this.landmark;
      } else if(section.elements[i]._name === this.constants.ROADSIDEFACTORS) {
        section.elements[i].score = this.totalRoadSideFactors;
      } else if(section.elements[i]._name === this.constants.ORDERATAXI) {
        section.elements[i].score = this.totalTaxi;
      }
    }

    this.totalCommunityMobility += this.totalTimeOrientation;
    this.totalCommunityMobility += this.timeCalculation;
    this.totalCommunityMobility += this.totalMoneyManagement;
    this.totalCommunityMobility += this.totalFuelCalculation;
    this.totalCommunityMobility += this.totalDirections;
    this.totalCommunityMobility += this.totalIdentifySigns;
    this.totalCommunityMobility += this.totalMemory;
    this.totalCommunityMobility += this.totalFindRoadTrafficSign;
    this.totalCommunityMobility += this.landmark;
    this.totalCommunityMobility += this.totalRoadSideFactors;
    this.totalCommunityMobility += this.totalTaxi;
    this.percCommunityMobility = (this.totalCommunityMobility / this.totalCommunityMobilityOutOf * 100).toFixed(0);

    section.scoreOutOf = this.totalCommunityMobilityOutOf;
    section.totalScore = this.totalCommunityMobility;
    section.scorePerc = this.percCommunityMobility;
  }

  setTransportation(): void {
    var section = this.reportJSON.sections.find((x: { _name: string; }) => x._name == this.constants.TRIPPLANNING);

    this.totalTransportation += this.totalTimeOrientation;
    this.totalTransportation += this.timeCalculation;
    this.totalTransportation += this.totalDirections;
    this.totalTransportation += this.totalTaxi;
    this.percTransportation = (this.totalTransportation / 12 * 100).toFixed(0);
  }

  switchReportLanguage(): void {

    this.reportJSON = this.apiService.getReportAssessment(this.reportLanguage, this.reportName);
    this.reportInsightJSON = this.apiService.getReportInsightInterpretation(this.reportLanguage);
    this.reportGeneralInfoJSON = this.apiService.getReportGeneralInformation(this.reportLanguage);
    this.resetAllScores();
    this.formatData();
  }

  resetAllScores(): void {
  
  //
  // TRIP PLANNING
  //

  this.placeOrientation = 0;
  this.timeHiddenDateTime;
  this.timeYear = 0;
  this.timeMonth = 0;
  this.timeWeekDay = 0;
  this.timeDay = 0;
  this.timeNoLookHour = 0;
  this.timeNoLookMinute = 0;
  this.timeLookHour = 0;
  this.timeLookMinute = 0;
  this.timeCalculation = 0;
  this.totalTimeOrientation = 0;
  this.percTimeOrientation = '0';
  this.totalDirections = 0;
  this.totalMoneyManagement = 0;
  this.totalFuelCalculation = 0;
  this.countryName = '';
  this.regionName = '';
  this.tripPlanningItems = [];
  this.communityItems = [];

  //
  // DRIVING ABILITY
  //

  this.totalIdentifySigns = 0;
  this.totalMemory = 0;
  this.totalMemory2 = 0;
  this.totalVisualMemory = 0;
  this.totalAuditoryMemory = 0;
  this.totalMemoryDirections = 0;
  this.totalFindRoadTrafficSign = 0;
  this.totalRoadRules = 0;
  this.totalDrivingManoeuvre = 0;
  this.totalDrivingManoeuvreFunc = 0;
  this.intersectionA = '';
  this.intersectionB = '';
  this.intersectionC = '';
  this.intersectionD = '';
  this.totalIntersections = 0;
  this.arrMemorySigns = Array();
  this.arrRoadSignClicks = Array();
  this.arrCarInteriorClicks = Array();
  this.arrRoadSideClicks = Array();
  this.landmarkClick1 = '';
  this.landmarkClick2 = '';
  this.landmarkClick3 = '';
  this.totalRoadSideFactors = 0;
  this.totalTaxi = 0;
  this.totalTripInstructions = 0;
  this.drivingAbilityItems = [];

  this.totalSelfAssessmentData = 0;
  this.percSelfAssessmentData = '0';
  this.totalTripPlanning = 0;
  this.totalTripPlanningOutOf = 0;
  this.percTripPlanning = '0';
  this.totalDrivingAbility = 0;
  this.totalDrivingAbilityOutOf = 0;
  this.percDrivingAbility = '0';
  this.percDrivingAbilityOutOf = 0;
  this.totalDrivingFitness = 0;
  this.totalDrivingFitnessOutOf = 0;
  this.percDrivingFitness = '0';
  this.totalCommunityMobility = 0;
  this.totalCommunityMobilityOutOf = 0;
  this.percCommunityMobility = '0';
  this.totalTransportation = 0;
  this.percTransportation = '0';
  this.totalInsight = 0;
  this.totalInsightOutOf = 0;

  this.landmark = 0;

  this.totalDiff = 0;
  }

  getChart(): void {
    const graph = document.getElementById('selfChart');
    if (graph) {
      html2canvas(graph).then(canvas => {
        this.sendURI(canvas.toDataURL('image/png'));
      });
    }
  }

  sendURI(htmlURI: string) {
    this.chartURI = htmlURI;
    this.print();
  }

  print(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    const postData: any =  {};
    postData.token = id;
    postData.today = this.today;

    postData.reportJSON = this.reportJSON;
    postData.reportInsightJSON = this.reportInsightJSON;
    postData.reportGeneralInfoJSON = this.reportGeneralInfoJSON;
    postData.langauge = this.reportLanguage;
   
    postData.chartURI = this.chartURI;
    postData.column1Perc = this.column1Perc;
    postData.column2Perc = this.column2Perc;
    postData.totalDiff = this.totalDiff;

    postData.downloadReport = this.downloadReport;

    postData.toAddress = this.toAddress;
    postData.ccAddress = this.ccAddress;
    postData.participantName = this.participantName;
    postData.assessmentName = this.reportName;

    this.assessmentService.generatePDF(postData).subscribe(
      data => {
        this.showEmailBox = false;
        this.downloadPdfFile(data);
      },
      err => {
        console.log('error generatePDF', err);
      }
    );

  }

  downloadPdfFile(url: any): void {

   // window.location.assign(url)
   this.bShowLoader = false;
   const a = document.createElement("a");
    a.setAttribute('href', url);
    a.setAttribute('target', '_blank');
    a.click();
    //document.body.removeChild(a);

  
  }

  sendEmail(): void {
    this.downloadReport = false;
    this.showEmailBox = false;
    this.emailStatus = 'Email has been sent!';
    this.getChart();
  }

  download(): void {
    this.bShowLoader = true;
    this.emailStatus = '';
    this.downloadReport = true;
    this.getChart();
  }
}
