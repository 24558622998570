import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constants';

@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.css']
})
export class CompleteComponent implements OnInit {

  assessmentLanguage = '';
  completeSentence = 'Thank you for completing the assessment.';
  completeButtonText = 'View Scoring Results';

  constructor(private router: Router, private activatedRoute: ActivatedRoute, public constants: AppConstants) { 

    console.log('complete constructor');
  }

  ngOnInit(): void {
    console.log('complete init');
    this.assessmentLanguage = localStorage.getItem('assessmentLanguage')?.toString()!;
    console.log('assessmentLanguage', this.assessmentLanguage);
    if (this.assessmentLanguage === '' || this.assessmentLanguage === this.constants.ENGLISH) {
      this.completeSentence = this.constants.ENG_COMPLETE_SENTENCE;
      this.completeButtonText = this.constants.ENG_COMPLETE_BUTTON_TEXT;
    } else if (this.assessmentLanguage === this.constants.AFRIKAANS) {
      this.completeSentence = this.constants.AFR_COMPLETE_SENTENCE;
      this.completeButtonText = this.constants.AFR_COMPLETE_BUTTON_TEXT;
    } else if (this.assessmentLanguage == this.constants.ZULU) {
      this.completeSentence = this.constants.ZUL_COMPLETE_SENTENCE;
      this.completeButtonText = this.constants.Zul_COMPLETE_BUTTON_TEXT;
    } else if (this.assessmentLanguage == this.constants.XHOSA) {
      this.completeSentence = this.constants.XHO_COMPLETE_SENTENCE;
      this.completeButtonText = this.constants.XHO_COMPLETE_BUTTON_TEXT;
    }
  }

  viewReport(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.router.navigate(['report/' + id ] );
  }

}
