import { ResultsComponent } from './pages/results/results.component';
import { TherapistReportComponent } from './pages/therapist/therapist-report.component';
import { VideoButtons3Component } from './pages/fun-cog/video-buttons.component3';
import { VideoButtons2Component } from './pages/fun-cog/video-buttons.component2';
import { TherapistComponent } from './pages/therapist/therapist.component';
import { TokensComponent } from './pages/tokens/tokens.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FunCogComponent } from './pages/fun-cog/fun-cog.component';
import { ReportComponent } from './pages/report/report.component';
import { CompleteComponent } from './pages/complete/complete.component';
import { VideoButtonsComponent } from './pages/fun-cog/video-buttons.component';
import { CarInteriorSvgComponent } from './pages/fun-cog/car-interior-svg.components';
import { RoadSignSvgComponent } from './pages/fun-cog/road-sign-svg.component';
import { RoadsideFactorsSvgComponent } from './pages/fun-cog/roadside-factors.component';
import { HomeComponent } from './pages/home/home.component';
import { TherapistCompleteComponent } from './pages/therapist/therapist-complete.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { IntroComponent } from './pages/intro/intro.component';
import { AuthGuard } from './pages/_services/auth.guard';
import { LandMarkSvgEnglishComponent } from './pages/fun-cog/land-mark-svg-english.component';
import { LandMarkSvg2EnglishComponent } from './pages/fun-cog/land-mark-svg2-english.component';
import { LandMarkSvg3EnglishComponent } from './pages/fun-cog/land-mark-svg3-english.component';
import { LandMarkSvgAfrikaansComponent } from './pages/fun-cog/land-mark-svg-afrikaans.component';
import { LandMarkSvg2AfrikaansComponent } from './pages/fun-cog/land-mark-svg2-afrikaans.component';
import { LandMarkSvg3AfrikaansComponent } from './pages/fun-cog/land-mark-svg3-afrikaans.component';
import { LandMarkSvgZuluComponent } from './pages/fun-cog/land-mark-svg-zulu.component';
import { LandMarkSvg2ZuluComponent } from './pages/fun-cog/land-mark-svg2-zulu.component';
import { LandMarkSvg3ZuluComponent } from './pages/fun-cog/land-mark-svg3-zulu.component';
import { LandMarkSvgXhosaComponent } from './pages/fun-cog/land-mark-svg-xhosa.component';
import { LandMarkSvg2XhosaComponent } from './pages/fun-cog/land-mark-svg2-xhosa.component';
import { LandMarkSvg3XhosaComponent } from './pages/fun-cog/land-mark-svg3-xhosa.component';
// import { AuthGuard } from './pages/_services/auth.guard';


const routes: Routes = [
  { path: '', redirectTo: '/intro', pathMatch: 'full'},
  { path: 'home', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard]  },
  { path: 'tokens', component: TokensComponent },
  { path: 'funCog/:token', component: FunCogComponent},
  { path: 'therapist', component: TherapistComponent },
  { path: 'trafficSignSvg', component: RoadSignSvgComponent },
  { path: 'roadsideFactorsSvg1', component: RoadsideFactorsSvgComponent },
  { path: 'roadsideFactorsSvg2', component: RoadsideFactorsSvgComponent },
  { path: 'carInteriorSvg', component: CarInteriorSvgComponent },
  { path: 'landmarkSvgEnglish', component: LandMarkSvgEnglishComponent },
  { path: 'landmarkSvg2English', component: LandMarkSvg2EnglishComponent },
  { path: 'landmarkSvg3English', component: LandMarkSvg3EnglishComponent },
  { path: 'landmarkSvgAfrikaans', component: LandMarkSvgAfrikaansComponent },
  { path: 'landmarkSvg2Afrikaans', component: LandMarkSvg2AfrikaansComponent },
  { path: 'landmarkSvg3Afrikaans', component: LandMarkSvg3AfrikaansComponent },
  { path: 'landmarkSvgZulu', component: LandMarkSvgZuluComponent },
  { path: 'landmarkSvg2Zulu', component: LandMarkSvg2ZuluComponent },
  { path: 'landmarkSvg3Zulu', component: LandMarkSvg3ZuluComponent },
  { path: 'landmarkSvgXhosa', component: LandMarkSvgXhosaComponent },
  { path: 'landmarkSvg2Xhosa', component: LandMarkSvg2XhosaComponent },
  { path: 'landmarkSvg3Xhosa', component: LandMarkSvg3XhosaComponent },
  { path: 'complete/:id', component: CompleteComponent, pathMatch: 'full' },
  { path: 'tcomplete', component: TherapistCompleteComponent },
  { path: 'report/:id', component: ReportComponent, pathMatch: 'full'},
  { path: 'treport', component: TherapistReportComponent },
  { path: 'video1', component: VideoButtonsComponent },
  { path: 'video2', component: VideoButtons2Component },
  { path: 'video3', component: VideoButtons3Component },
  { path: 'results', component: ResultsComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  { path: 'intro', component: IntroComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {  }
