import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from './token-storage.service';

const API_URL = environment.apiUrl;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AssessmentService {
  constructor(private http: HttpClient, private tokenStorage: TokenStorageService) { }

  getUserTokens(username: string): Observable<any> {
    const postData: any =  {};
    postData.username = username;
    const url = API_URL + 'assets/api/fetchUserTokens.php'
    return this.http.post(url, JSON.stringify(postData), httpOptions);
  }

  addUserToken(token: string, assessmentType: string): Observable<any> {
    const postData: any =  {};
    postData.username = this.tokenStorage.getUsername();
    postData.token = token;
    postData.assessmentType = assessmentType;
    const url = API_URL + 'assets/api/addUserToken.php'
    return this.http.post(url, JSON.stringify(postData), httpOptions);
  }

  getTokenData(token: string): Observable<any> {
    const postData: any =  {};
    postData.token = token;
    postData.username = this.tokenStorage.getUsername();
    const url = API_URL + 'assets/api/getTokenData.php'
    return this.http.post(url, JSON.stringify(postData), httpOptions);
  }

  getAssessmentData(token: string): Observable<any> {
    const postData: any =  {};
    postData.token = token;
    postData.username = this.tokenStorage.getUsername();
    const url = API_URL + 'assets/api/fetch.php'
    return this.http.post(url, JSON.stringify(postData), httpOptions);
  }

  generatePDF(postData: any): Observable<any> {
    const url = API_URL + 'assets/api/generateJSONPDF.php'
    //const url = 'http://localhost/drivingAssessment/assets/api/generateJSONPDF.php'
    postData.username = this.tokenStorage.getUsername();
    return this.http.post(url, JSON.stringify(postData), httpOptions);
  }

  allocateNameToToken(token: string, participantName: string): Observable<any> {
    const postData: any =  {};
    postData.token = token;
    postData.participantName = participantName;
    postData.username = this.tokenStorage.getUsername();
    const url = API_URL + 'assets/api/allocateNameToToken.php'
    return this.http.post(url, JSON.stringify(postData), httpOptions);
  }

  getReportData(reportType: string): Observable<any> {
    const postData: any =  {};
    postData.reportType = reportType;
    postData.username = this.tokenStorage.getUsername();
    const url = API_URL + 'assets/api/read.php'
    return this.http.post(url, JSON.stringify(postData), httpOptions);
  }

  fetchInProgress(token: string): Observable<any> {
    const postData: any =  {};
    postData.token = token;
    postData.username = this.tokenStorage.getUsername();
    const url = API_URL + 'assets/api/fetchInProgress.php'
    return this.http.post(url, JSON.stringify(postData), httpOptions);
  }

}
