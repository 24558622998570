<div class="loading" *ngIf="bShowLoader"></div>
<div class="homecontainer">
  <app-header [showLogout]="true" [showTitle]="false" [showBack]="false" [showResults]="showResultsButton"></app-header>
    <div class="content">
      
      <!-- Buttons to generate new token and refresh page -->
      <nav class="navbar">
        <button type="button" class="btn btn-link btn-nav-bar" (click)="generateToken()" *ngIf="showResultsButton">
          <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path d="M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3L1 9l11 6 9-4.91V17h2V9L12 3z"/></svg>
          Generate Token
        </button>
        <button type="button" class="btn btn-link btn-nav-bar" (click)="getUserTokens()">
          <svg xmlns="http://www.w3.org/2000/svg" height="20"  width="20" viewBox="0 0 20 20"><path d="M0 0h24v24H0z" fill="none"/><path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"/></svg>
          Refresh
        </button>
      </nav>

      <div class="row text-center" *ngIf="sToken !== ''">
        <div class="col-xs-12 col-sm-12">
            <a #clientToken href="{{clientUrl}}/c-{{sToken}}" target="_blank">{{clientUrl}}/c-{{sToken}}</a>&nbsp;
            <select class="form-select form-select-sm" [(ngModel)]="assessmentType">
              <option value="CoDriS">CoDriS</option>
              <option value="CoDriSEmployment">CoDriS Employment</option>
            </select>
            <button style="margin-left: 9px;" class="btn btn-outline-primary btn-sm" (click)="addTokenToList('c-' + sToken)">Add</button>
        </div>
    </div>

      

      <h2 *ngIf="newTokens.length > 0">NEW</h2>
      <div id="no-more-tables">
        <table class="table table-striped" *ngIf="newTokens.length > 0">
          <thead>
            <tr>
              <th scope="col" width="1%">#</th>
              <th scope="col">Token</th>
              <th scope="col">Allocated To</th>
              <th scope="col">Copy</th>
              <th scope="col">Type</th>
              <th scope="col">Date Purchased</th>
              <th scope="col" width="1%">Order #</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of newTokens; let i = index;">
              <th data-title="Nr">{{i+1}}</th>
              <td data-title="Token">
                <button type="button" class="btn btn-link tokenButton" (click)="startAssessment(item.token)" title="{{startButtonTitle}}">{{linkButtonName}}</button>
              </td>
              <td data-title="Allocated To">
                <div>
                  <span style="text-align: center;" *ngIf="item.participantName && !item.showAllocateBox">
                    {{item.participantName}}
                  </span>
                  <span class="material-icons" style="cursor: pointer;" (click)="item.showAllocateBox = !item.showAllocateBox" *ngIf="!item.showAllocateBox" title="{{penTitle}}">
                    edit
                  </span>
                </div>
                <div style="margin-top: 10px;margin-bottom: 14px;display: flex;" *ngIf="item.showAllocateBox">
                  <input type="text" class="form-control allocateName" placeholder="Allocate to" [(ngModel)]="item.participantName">
                  <i class="material-icons pt-5" style="cursor: pointer;" title="Save" (click)="allocateName(item)">save</i>
                </div>
              </td>
              <td data-title="Copy">
                <button class="btn btn-outline-primary btn-sm ml-4 pl-8" (click)="copyClientUrl(item.token)" title="{{copyButtonTitle}}">Copy Token</button>
              </td>
              <td data-title="Type">{{item.assessmentName}}</td>
              <td data-title="Date Purchased" *ngIf="item.date_created_gmt !== '0000-00-00 00:00:00'">{{item.date_created_gmt | date:'yyyy-MM-dd H:m'}}</td>
              <td data-title="Date Purchased" *ngIf="item.date_created_gmt === '0000-00-00 00:00:00'">{{item.dDateCreated | date:'yyyy-MM-dd H:m'}}</td>
              <td data-title="Order #">{{item.order_id}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h2 *ngIf="inProgressTokens.length > 0">IN PROGRESS</h2>
        <div id="no-more-tables">
          <table class="table table-striped" *ngIf="inProgressTokens.length > 0">
            <thead  class="thead-light">
              <tr>
                <th scope="col" width="1%">#</th>
                <th scope="col">Token</th>
                <th scope="col">Allocated To</th>
                <th scope="col">Copy</th>
                <th scope="col">Type</th>
                <th scope="col">Date Purchased</th>
                <th scope="col">Date Started</th>
                <th scope="col" width="1%">Order #</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of inProgressTokens; let i = index;">
                <th data-title="Nr">{{i+1}}</th>
                <td data-title="Token">
                  <button type="button" class="btn btn-link tokenButton" (click)="startAssessment(item.token)" title="Click to continue with CoDriS assessment">{{linkButtonName}}</button>
                </td>
                <td data-title="Allocated To">
                  <div>
                    <span style="text-align: center;" *ngIf="item.participantName && !item.showAllocateBox">
                      {{item.participantName}}
                    </span>
                    <span class="material-icons" style="cursor: pointer;" (click)="item.showAllocateBox = !item.showAllocateBox" *ngIf="!item.showAllocateBox" title="{{penTitle}}">
                      edit
                    </span>
                  </div>
                  <div style="margin-top: 10px;margin-bottom: 14px;display: flex;" *ngIf="item.showAllocateBox">
                    <input type="text" class="form-control allocateName" placeholder="Allocate to" [(ngModel)]="item.participantName">
                    <i class="material-icons pt-5" style="cursor: pointer;" title="Save" (click)="allocateName(item)">save</i>
                  </div>
                </td>
                <td data-title="Copy"><button class="btn btn-outline-primary btn-sm ml-4 pl-8" (click)="copyClientUrl(item.token)" title="{{copyButtonTitle}}">Copy Token</button></td>
                <td data-title="Type">{{item.assessmentName}}</td>
                <td data-title="Date Purchased" *ngIf="item.date_created_gmt !== '0000-00-00 00:00:00'">{{item.date_created_gmt | date:'yyyy-MM-dd H:m'}}</td>
                <td data-title="Date Purchased" *ngIf="item.date_created_gmt === '0000-00-00 00:00:00'">{{item.dDateCreated | date:'yyyy-MM-dd H:m'}}</td>
                <td data-title="Date Started">{{item.dDateCreated | date:'yyyy-MM-dd H:m'}}</td>
                <td data-title="Order #">{{item.order_id}}</td>
              </tr>
            </tbody>
          </table>
        </div>

      <h2 *ngIf="completedTokens.length > 0">COMPLETED</h2>
      <div id="no-more-tables">
      <table class="table table-striped" *ngIf="completedTokens.length > 0">
        <thead  class="thead-light">
          <tr>
            <th scope="col" width="1%">#</th>
            <th scope="col">Token</th>
            <th scope="col">Allocated To</th>
            <th scope="col">Copy</th>
            <th scope="col">Type</th>
            <th scope="col">Date Purchased</th>
            <th scope="col">Date Completed</th>
            <th scope="col" width="1%">Order #</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of completedTokens; let i = index;">
            <th data-title="Nr">{{i+1}}</th>
            <td data-title="Token">
              <button type="button" class="btn btn-link tokenButton" (click)="viewAssessment(item.token)" title="Click to view CoDriS report">{{linkButtonName}}</button>
            </td>
            <td data-title="Allocated To">
              <div>
                <span style="text-align: center;" *ngIf="item.participantName && !item.showAllocateBox">
                  {{item.participantName}}
                </span>
                <span class="material-icons" style="cursor: pointer;" (click)="item.showAllocateBox = !item.showAllocateBox" *ngIf="!item.showAllocateBox" title="{{penTitle}}">
                  edit
                </span>
              </div>
              <div style="margin-top: 10px;margin-bottom: 14px;display: flex;" *ngIf="item.showAllocateBox">
                <input type="text" class="form-control allocateName" placeholder="Allocate to" [(ngModel)]="item.participantName">
                <i class="material-icons pt-5" style="cursor: pointer;" title="Save" (click)="allocateName(item)">save</i>
              </div>
            </td>
            <td data-title="Copy"><button class="btn btn-outline-primary btn-sm ml-4 pl-8" (click)="copyReportUrl(item.token)"  title="{{copyButtonTitle}}">Copy Token</button></td>
            <td data-title="Type">{{item.assessmentName}}</td>
            <td data-title="Date Purchased" *ngIf="item.date_created_gmt !== '0000-00-00 00:00:00'">{{item.date_created_gmt | date:'yyyy-MM-dd H:m'}}</td>
            <td data-title="Date Purchased" *ngIf="item.date_created_gmt === '0000-00-00 00:00:00'">{{item.dDateCreated | date:'yyyy-MM-dd H:m'}}</td>
            <td data-title="Date Completed">{{item.completeDate | date:'yyyy-MM-dd H:m'}}</td>
            <td data-title="Order #">{{item.order_id}}</td>
          </tr>
        </tbody>
      </table>


    </div>
  <app-footer></app-footer>
</div>