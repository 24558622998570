import { IframeTrackerDirective } from './pages/fun-cog/iframe-tracker.directive';
import { VideoButtons3Component } from './pages/fun-cog/video-buttons.component3';
import { VideoButtons2Component } from './pages/fun-cog/video-buttons.component2';
import { VideoButtonsComponent } from './pages/fun-cog/video-buttons.component';
import { RoadSignSvgComponent } from './pages/fun-cog/road-sign-svg.component';
import { ReportComponent } from './pages/report/report.component';
import { FunCogComponent } from './pages/fun-cog/fun-cog.component';
import { CompleteComponent } from './pages/complete/complete.component';
import { CarInteriorSvgComponent } from './pages/fun-cog/car-interior-svg.components';
import { HomeComponent } from './pages/home/home.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RoadsideFactorsSvgComponent } from './pages/fun-cog/roadside-factors.component';
import { ChartsModule } from 'ng2-charts';
import { TokensComponent } from './pages/tokens/tokens.component';
import { TherapistComponent } from './pages/therapist/therapist.component';
import { TherapistCompleteComponent } from './pages/therapist/therapist-complete.component';
import { TherapistReportComponent } from './pages/therapist/therapist-report.component';
import { ClipboardModule } from 'ngx-clipboard';
import { ResultsComponent } from './pages/results/results.component';
import { LoginComponent } from './pages/login/login.component';
import { authInterceptorProviders } from './pages/_helpers/auth.interceptor';
import { HeaderComponent } from './pages/footer-header/header.component';
import { FooterComponent } from './pages/footer-header/footer.component';
import { RegisterComponent } from './pages/register/register.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TabsComponent } from './pages/_components/tabs/tabs.component';
import { TabComponent } from './pages/_components/tabs/tab.component';
import { DemographicsComponent } from './pages/results/components/demographics.component';
import { DrivingFitnessComponent } from './pages/results/components/driving-fitness.component';
import { CommunityMobilityComponent } from './pages/results/components/community-mobility.component';
import { TestItemComponent } from './pages/results/components/test-item.component';
import { RawDataComponent } from './pages/results/components/raw-data.component';
import { TimeItemComponent } from './pages/results/components/times.component';
import { IntroComponent } from './pages/intro/intro.component';
import { LandMarkSvgEnglishComponent } from './pages/fun-cog/land-mark-svg-english.component';
import { LandMarkSvg2EnglishComponent } from './pages/fun-cog/land-mark-svg2-english.component';
import { LandMarkSvg3EnglishComponent } from './pages/fun-cog/land-mark-svg3-english.component';
import { LandMarkSvgAfrikaansComponent } from './pages/fun-cog/land-mark-svg-afrikaans.component';
import { LandMarkSvg2AfrikaansComponent } from './pages/fun-cog/land-mark-svg2-afrikaans.component';
import { LandMarkSvg3AfrikaansComponent } from './pages/fun-cog/land-mark-svg3-afrikaans.component';
import { LandMarkSvgZuluComponent } from './pages/fun-cog/land-mark-svg-zulu.component';
import { LandMarkSvg2ZuluComponent } from './pages/fun-cog/land-mark-svg2-zulu.component';
import { LandMarkSvg3ZuluComponent } from './pages/fun-cog/land-mark-svg3-zulu.component';
import { LandMarkSvgXhosaComponent } from './pages/fun-cog/land-mark-svg-xhosa.component';
import { LandMarkSvg2XhosaComponent } from './pages/fun-cog/land-mark-svg2-xhosa.component';
import { LandMarkSvg3XhosaComponent } from './pages/fun-cog/land-mark-svg3-xhosa.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FunCogComponent,
    CompleteComponent,
    CarInteriorSvgComponent,
    ReportComponent,
    RoadSignSvgComponent,
    RoadsideFactorsSvgComponent,
    LandMarkSvgEnglishComponent,
    LandMarkSvg2EnglishComponent,
    LandMarkSvg3EnglishComponent,
    LandMarkSvgAfrikaansComponent,
    LandMarkSvg2AfrikaansComponent,
    LandMarkSvg3AfrikaansComponent,
    LandMarkSvgZuluComponent,
    LandMarkSvg2ZuluComponent,
    LandMarkSvg3ZuluComponent,
    LandMarkSvgXhosaComponent,
    LandMarkSvg2XhosaComponent,
    LandMarkSvg3XhosaComponent,
    VideoButtonsComponent,
    VideoButtons2Component,
    VideoButtons3Component,
    TokensComponent,
    TherapistComponent,
    TherapistCompleteComponent,
    TherapistReportComponent,
    IframeTrackerDirective,
    ResultsComponent,
    LoginComponent,
    RegisterComponent,
    HeaderComponent,
    FooterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    TabsComponent,
    TabComponent,
    DemographicsComponent,
    DrivingFitnessComponent,
    CommunityMobilityComponent,
    TestItemComponent,
    RawDataComponent,
    TimeItemComponent,
    IntroComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ChartsModule,
    ClipboardModule,
    BrowserAnimationsModule
  ],
  providers: [authInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
