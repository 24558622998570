import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import * as Survey from 'survey-angular';
import * as widgets from 'surveyjs-widgets';
import { environment } from './../../../environments/environment';
import { TokenStorageService } from '../_services/token-storage.service';
import { AssessmentService } from '../_services/assessment.service';
import { AppConstants } from 'src/app/app.constants';

widgets.select2(Survey);

function saveSurveyData (survey: any) {
  const token = survey.getVariable('token');
  if (token != "") {
    const countryName = survey.getVariable('countryName');
    const regionName = survey.getVariable('regionName');
    const http = survey.getVariable('http');
    const storageItemKey = 'storage-' + token;
    const data = survey.data;
    data.pageNo = survey.currentPageNo;
    data.countryName = countryName;
    data.regionName = regionName;

    let tmp: any;
    tmp = localStorage.getItem('arrRoadSignClicks');
    data.RoadSignClicks = tmp ? JSON.parse(tmp) : [];
    tmp = localStorage.getItem('arrCarInteriorClicks');
    data.CarInteriorClicks = tmp ? JSON.parse(tmp) : [];
    tmp = localStorage.getItem('arrRoadSideClicks');
    data.RoadSideClicks = tmp ? JSON.parse(tmp) : [];
    tmp = localStorage.getItem('LANDMARKq1');
    data.LANDMARK_q1 = tmp ? tmp : '';
    tmp = localStorage.getItem('LANDMARKq2');
    data.LANDMARK_q2 = tmp ? tmp : '';
    tmp = localStorage.getItem('LANDMARKq3');
    data.LANDMARK_q3 = tmp ? tmp : '';
    tmp = localStorage.getItem('video1');
    data.video1 = tmp ? JSON.parse(tmp) : {};
    tmp = localStorage.getItem('video2');
    data.video2 = tmp ? JSON.parse(tmp) : {};
    tmp = localStorage.getItem('video3');
    data.video3 = tmp ? JSON.parse(tmp) : {};

    const pagesTime = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < survey.pages.length; i++) {
      pagesTime.push({title: survey.pages[i].title, time: survey.pages[i].timeSpent});
    }
    data.pagesTime = pagesTime;

    window.localStorage.setItem(storageItemKey, JSON.stringify(data));

    // saving the data to the server to different table, because it is running
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    const postData: any =  {};
      postData.token = token;
      postData.totalTimeSpent = survey.timeSpent;
      postData.result = data;
      postData.pagesTime = pagesTime;
      postData.result.countryName = countryName;
      postData.result.regionName = regionName;
      postData.username = window.localStorage.getItem('username');

    http.post(environment.apiUrl + 'assets/api/updateInProgress.php', JSON.stringify(postData), httpOptions).subscribe((res: any) => {
      //console.log('updating in progress data', res);
    });
  }

}

const engLocale = Survey.surveyLocalization.locales["en"];
engLocale.pageNextText = "Next";
engLocale.completeText = "Complete";

@Component({
  selector: 'app-fun-cog',
  templateUrl: './fun-cog.component.html',
  // styleUrls: ['./fun-cog.component.css']
})
export class FunCogComponent implements OnInit, OnDestroy {

  @Output() submitSurvey = new EventEmitter<any>();

  surveyJSON: any = [];
  surveyResult: any;
  timerResults: any = [];
  survey: any = new Survey.Model();
  ipAddress = '';

  subscription: Subscription = new Subscription();
  message = '';
  token = '';
  storageItemKey = '';
  showBack = true;
  assessmentName = this.constants.CODRIS;
  participantName = '';
  assessmentLanguage = this.constants.ENGLISH;
  

  constructor(private http: HttpClient,
              private apiService: ApiService,
              private activatedRoute: ActivatedRoute,
              private tokenStorageService: TokenStorageService,
              private assessmentService: AssessmentService,
              public constants: AppConstants
    ) {
  }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(p => {
      this.token = p.token;
    });

    localStorage.setItem('token', this.token);
    this.storageItemKey = 'storage-' + this.token;
    this.showBack = !!this.tokenStorageService.getAuthToken();

    this.getTokenData();
  }

  switchLanguage(): void {
    this.initSurvey();
  }

  getTokenData(): void {
    this.assessmentService.getTokenData(this.token).subscribe(
      data => {
        this.assessmentName = data.assessmentName;
        this.participantName = data.participantName;
        this.initSurvey();
        this.getIPAddress();
      },
      err => {
       console.log('error fetching token data', err);
      }
    );
 }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getIPAddress(): void  {
    if (environment.production) {
      this.http.get('https://api.ipify.org/?format=json').subscribe((res: any) => {
        this.ipAddress = res.ip;
        this.getIPInfo();
      });
    }
  }

  getIPInfo(): void {
    this.http.get('https://ipinfo.io/' + this.ipAddress + '?token=50285fb07c6a19').subscribe((res: any) => {
       this.apiService.countryName = res.country;
       this.apiService.regionName = res.region;
    });
  }

  initSurvey(): void {

    let surveyJSON;
    surveyJSON = this.apiService.getNewAssessment(this.token, this.assessmentLanguage, this.assessmentName);

    const myCss = {
      root: 'sv_main',
      navigationButton: 'btn btn-primary pull-right',
      title: 'survey-title',
      logo: 'sv_logo',
      navigation: {
        complete: 'btn sv_complete_btn',
        next: 'btn sv_next_btn'
      },
      page: {
        title: 'page-title'
      },
      question: {
        content: 'question-content',
        description: 'question-description',
        title: 'question-title'
      },
      radiogroup: {
        label: 'radio_label',
        itemChecked: 'checked',
        itemDecorator: 'sv-hidden'
      },
      imagepicker: {
        image: 'sv_q_imgsel_image',
        itemControl: 'sv_q_imgsel_control_item'
      },
      image: {
        root: 'sv_q_image',
        image: 'sv_image_image'
      },
      dropdown: {
        control: 'form-control'
      }
    };

    widgets.select2['settings.placeholder'] = 'helo';

    localStorage.setItem('assessmentLanguage', this.assessmentLanguage);

    if (this.assessmentLanguage == this.constants.AFRIKAANS) {
      engLocale.pageNextText = 'Volgende';
      engLocale.completeText = 'Voltooi';
    } else if (this.assessmentLanguage == this.constants.ENGLISH) {
      engLocale.pageNextText = 'Next';
      engLocale.completeText = 'Complete';
    } else if (this.assessmentLanguage == this.constants.ZULU) {
      engLocale.pageNextText = 'Phenya';
      engLocale.completeText = 'Qedela';
    } else if (this.assessmentLanguage == this.constants.XHOSA) {
      engLocale.pageNextText = 'Elandelayo';
      engLocale.completeText = 'Gqibezela';
    }

    Survey.StylesManager.applyTheme('bootstrap');
    Survey.surveyLocalization.locales["my"] = engLocale;

    this.survey = new Survey.Model(surveyJSON);

    this.survey.questionTitleTemplate = '{title}',
    this.survey.requiredText = ' ';
    this.survey.showProgressBar = '';
    this.survey.showCompletedPage = true;
    this.survey.focusFirstQuestionAutomatic = true;
    this.survey.showPageTitles = true;
    this.survey.showQuestionNumbers = '';
    this.survey.questionTitleLocation = 'top';
    this.survey.locale = 'custom';
    this.survey.clearInvisibleValues = 'none';
    this.survey.showPrevButton = false;
    this.survey.showTimerPanel = 'top';
    this.survey.showTimerPanelMode = 'page';
    this.survey.surveyShowDataSaving = true;
    this.survey.setVariable('ApiService', this.apiService);
    this.survey.setVariable('token', this.token);
    this.survey.setVariable('countryName', this.apiService.countryName);
    this.survey.setVariable('regionName', this.apiService.regionName);
    this.survey.setVariable('http', this.http);
    
    this.survey.onCurrentPageChanged.add(saveSurveyData);


    this.assessmentService.fetchInProgress(this.token).subscribe(
      result => {
        if (result.length) {
          const data = JSON.parse(result[0].assessmentData);
          window.localStorage.setItem(this.storageItemKey, JSON.stringify(data))
         this.survey.data = data;
         if (data.pageNo) {
            this.survey.currentPageNo = data.pageNo;
         }
        }

      },
      err => {
        console.log('error fetching in progress');
      }
    );

     // if the survey state is completed, lets post data from local storage to ensure it is available in db
    if (this.survey.state == 'completed') {
      this.apiService.redirectToComplete(this.survey.data, this.survey.pages, this.survey.timeSpent, this.token);
    }

    this.survey.onComplete.add((survey: any): void => {
        const pagesTime = [];
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < survey.pages.length; i++) {
          pagesTime.push({title: survey.pages[i].title, time: survey.pages[i].timeSpent});
        }

        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json'
            })
          };

        const token = survey.getVariable('token');
        const countryName = survey.getVariable('countryName');
        const regionName = survey.getVariable('regionName');

        const postData: any =  {};
        postData.token = token;
        postData.totalTimeSpent = survey.timeSpent;
        postData.result = survey.data;
        postData.pagesTime = pagesTime;
        postData.result.countryName = countryName;
        postData.result.regionName = regionName;

        const storedData = window.localStorage.getItem(this.storageItemKey) || null;

        // override all answers when we have stored data for the token + add last page answer
        if (storedData) {
          const parsedData = JSON.parse(storedData);
          parsedData['SELF_ASSESSMENT_performance'] = survey.data['SELF_ASSESSMENT_performance']
          postData.result = parsedData;
          postData.pagesTime = postData.result.pagesTime;
        }
        
        this.http.post(environment.apiUrl + 'assets/api/update.php', JSON.stringify(postData), httpOptions).subscribe((res: any) => {
          // console.log('res', res);
        });

        const apiService = survey.getVariable('ApiService');
        apiService.redirectToComplete(survey.data, survey.pages, survey.timeSpent, token);
  });

    this.survey.onAfterRenderPage.add(this.onAfterRenderPage);

    Survey.SurveyNG.render('surveyElement', { model: this.survey, css: myCss });
  }

  onAfterRenderPage(survey: any, page: any): void {
    // console.log('onAfterRenderPage', survey, page);
    survey.scrollToTopOnPageChange();
  }

}
