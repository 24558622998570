import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AppConstants {
    public CODRIS = "CoDriS";
    public CODRISEMPLOYMENT = "CoDriSEmployment";

    public ENGLISH = "English";
    public AFRIKAANS = "Afrikaans";
    public ZULU = "Zulu";
    public XHOSA = "Xhosa";

    // SECTIONS
    public TRIPPLANNING = "trip_planning";
    public DRIVINGABILITY = "driving_ability";
    public DRIVINGFITNESS = "driving_fitness";
    public DRIVINGFITNESSRISKPROFILE = "driving_fitness_risk_profile";
    public INSIGHTACTIVEDRIVER = "insight_active_driver";
    public INSIGHTNONACTIVEDRIVER = "insight_non_active_driver";
    public COMMUNITYMOBILITY = "community_mobility";
    public COMMUNITYMOBILITYRISKPROFILE = "community_mobility_risk_profile";

    // ELEMENTS
    public ORIENTATIONTOTIME = "orientation_to_time";
    public TIMECALCULATION = "time_calculation";
    public MONEYMANAGEMENT = "money_management";
    public FUELCALCULATION = "fuel_calculation";
    public DIRECTIONS = "directions";
    public ORDERATAXI = "order_a_taxi";
    public TRIPINSTRUCTIONS = "trip_instructions";
    public IDENTIFYTRAFFICSINGS= "identifying_traffic_signs";
    public VISUALMEMORY= "visual_memory";
    public AUDITORYMEMORY= "auditory_memory";
    public FINDTHETRAFFICSIGN = "find_the_traffic_sign";
    public INTERSECTIONS = "intersections";
    public LANDMARKNAVIGATION = "landmark_navigation";
    public ROADRULES = "road_rules";
    public ROADSIDEFACTORS = "roadside_factors";
    public DRIVINGMANOEUVRE = "driving_manoeuvre";
    public IGETLOSTWHILEDRIVING = "i_get_lost_while_driving_in_a_relatively_familiar_environment.";
    public IFORGETWHEREIWAS = "i_forget_where_i_was_planning_to_drive_to.";
    public IHAVEDIFFICULTYPLANNING = "i_have_difficulty_planning_my_appointments_independently.";
    public IHAVEDIFFICULTYDECIDING = "i_have_difficulty_deciding_who_has_right_of_way_at_an_intersection.";
    public IGETNERVOUSAT = "i_get_nervous_at_an_intersection_because_there_is_so_much_to_watch_out_for.";
    public IFINDITDIFFICULT = "i_find_it_difficult_to_merge_with_traffic_on_a_busy_road.";
    public IGETSURPRISED= "i_get_surprised_by_vehicles_or_pedestrians_who_appear_out_of_nowhere.";
    public MEMORY = "memory";

    // COMPLETE
    public ENG_COMPLETE_SENTENCE = "Thank you for completing the assessment.";
    public ENG_COMPLETE_BUTTON_TEXT = "View Scoring Results";
    public AFR_COMPLETE_SENTENCE = "Dankie dat jy die assessering voltooi het.";
    public AFR_COMPLETE_BUTTON_TEXT = "Sien Punteresultate";
    public ZUL_COMPLETE_SENTENCE = "Ngiyabonga ukuthi ukuqede lokhu kuhlolwa.";
    public Zul_COMPLETE_BUTTON_TEXT = "Bheka-ke imiphumela yakho";
    public XHO_COMPLETE_SENTENCE = "Ndiyabulela ngokugqiba olu vavanyo.";
    public XHO_COMPLETE_BUTTON_TEXT = "Jonga iziphumo zamanqaku";

}
