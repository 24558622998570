import { ActivatedRoute } from '@angular/router';
import { ApiTherapistService } from './../../api-therapist.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import * as Survey from 'survey-angular';
import * as widgets from "surveyjs-widgets";
import { environment } from './../../../environments/environment';

widgets.select2(Survey);

@Component({
  selector: 'app-therapist',
  templateUrl: './therapist.component.html',
  styleUrls: ['./therapist.component.css']
})

export class TherapistComponent implements OnInit {

  surveyJSON: any = [];
  token = '';
  survey: any = new Survey.Model();
  ipAddress = '';

  constructor(private http: HttpClient,
              private apiService: ApiTherapistService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params.token;
    });

    localStorage.setItem('token', this.token);

    this.initSurvey();
  }

  initSurvey(): void {
    const surveyJSON = this.apiService.getNewAssessment(this.token);

    // console.log('surveyJSON', surveyJSON);

    const myCss = {
      root: 'sv_main',
      navigationButton: 'btn btn-primary pull-right',
      title: 'survey-title',
      logo: 'sv_logo',
      navigation: {
        complete: 'btn sv_complete_btn',
        next: 'btn sv_next_btn'
      },
      page: {
        title: 'page-title'
      },
      question: {
        content: 'question-content',
        description: 'question-description',
        title: 'question-title'
      },
      radiogroup: {
        label: 'radio_label',
        itemChecked: 'checked',
        itemDecorator: 'sv-hidden'
      },
      imagepicker: {
        image: 'sv_q_imgsel_image',
        itemControl: 'sv_q_imgsel_control_item'
      },
      image: {
        root: 'sv_q_image',
        image: 'sv_image_image'
      },
      dropdown: {
        // control: 'form-control'
      }
    };

    Survey.StylesManager.applyTheme('bootstrap');
    this.survey = new Survey.Model(surveyJSON);

    this.survey.questionTitleTemplate = '{title}',
    this.survey.requiredText = ' ';
    this.survey.showProgressBar = '';
    this.survey.showCompletedPage = true;
    this.survey.focusFirstQuestionAutomatic = true;
    this.survey.showPageTitles = true;
    this.survey.showQuestionNumbers = '';
    this.survey.questionTitleLocation = 'top';
    this.survey.locale = 'custom';
    this.survey.clearInvisibleValues = 'none';
    this.survey.showPrevButton = false;
    this.survey.showTimerPanel = 'top';
    this.survey.showTimerPanelMode = 'page';
    this.survey.surveyShowDataSaving = true;
    this.survey.setVariable('ApiService', this.apiService);
    this.survey.setVariable('token', this.token);

    this.survey.onComplete.add((survey: any): void => {

    const pagesTime = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < survey.pages.length; i++) {
      pagesTime.push({title: survey.pages[i].title, time: survey.pages[i].timeSpent});
    }

    const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };

    const token = survey.getVariable('token');
    const postData: any =  {};
    postData.token = token;
    postData.totalTimeSpent = survey.timeSpent;
    postData.result = survey.data;
    postData.pagesTime = pagesTime;

    if (environment.production) {
      this.http.post(environment.apiUrl + 'assets/api/update.php', JSON.stringify(postData), httpOptions).subscribe((res: any) => {
        console.log('res', res);
      });
    }

    const apiService = survey.getVariable('ApiService');
    apiService.redirectToComplete(survey.data, survey.pages, survey.timeSpent);
  });

    this.survey.onAfterRenderPage.add(this.onAfterRenderPage);

    Survey.SurveyNG.render('surveyElement', { model: this.survey, css: myCss });
  }

  onAfterRenderPage(survey: any, page: any): void {
    // console.log('onAfterRenderPage', survey, page);
    survey.scrollToTopOnPageChange();
  }

}
