import { Component, OnDestroy, OnInit } from '@angular/core';
import { AssessmentService } from '../_services/assessment.service';
import { environment } from 'src/environments/environment';
import { ClipboardService } from 'ngx-clipboard';
import { TokenStorageService } from '../_services/token-storage.service';
import { Router } from '@angular/router';

export interface iTokenItem {
  id: number;
  order_id: number;
  billing_email: string;
  token: string;
  date_created_gmt: string;
  runningId: number;
  completedId: number;
  completeDate: string;
  dDateCreated: string;
  sortingDate: Date;
  participantName: string;
  assessmentName: string;
  showAllocateBox: boolean;
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  title = 'CoDriS';
  errorMessage = '';
  newTokens: iTokenItem[] = [];
  inProgressTokens: iTokenItem[] = [];
  completedTokens: iTokenItem[] = [];

  sToken = '';
  clientUrl = '';
  therapistUrl = '';
  reportUrl = '';
  username = '';
  showResultsButton = false;
  assessmentType = 'CoDriS';
  bShowLoader = false;
  copyButtonTitle = 'Copy and paste the token if you wish to share it by email or open it in another browser.';
  penTitle = 'Type and save participant name.';
  linkButtonName = 'Access CoDriS Token';
  startButtonTitle = 'Click to access CoDriS assessment';

  constructor(private assessmentService: AssessmentService, 
              private clipboardApi: ClipboardService, 
              private tokenStorageService: TokenStorageService, 
              private router: Router) {
  }

  ngOnInit(): void {

    var authToken = this.tokenStorageService.getAuthToken();
    if (authToken === null) {
      this.router.navigate(['intro']);
    }

    this.clientUrl = environment.clientUrl;
    this.therapistUrl = environment.therapistUrl;
    this.reportUrl = environment.reportUrl;
    this.username = this.tokenStorageService.getUsername();

    if (this.username === 'ulla@worthman.co.za' || this.username === 'liana@lwsoftware.co.za' || this.username === 'ulla@going-places.co.za') {
      this.showResultsButton = true;
    }

    if (this.username) {
      if (this.newTokens.length === 0 && this.inProgressTokens.length === 0 && this.completedTokens.length === 0) {
        this.getUserTokens();
      }
    }
   }

   getUserTokens(): void {
    this.bShowLoader = true;
      this.assessmentService.getUserTokens(this.tokenStorageService.getUsername()).subscribe(
        data => {
          if (data) {
            this.formatTokens(data);
          } else {
            this.bShowLoader = false;
          }
        },
        err => {
          this.errorMessage = err.error.message;
          this.bShowLoader = false;
        }
      );
   }


  formatTokens(data: iTokenItem[]): void {
    this.newTokens = [];
    this.inProgressTokens = [];
    this.completedTokens = [];
    for (var i=0; i < data.length; i++) {
      if (data[i]['runningId'] == null && data[i]['completedId'] == null) {
        data[i].sortingDate = new Date(data[i].date_created_gmt);
        this.newTokens.push(data[i]);
      }
      if (data[i]['runningId'] != null && data[i]['completedId'] == null) {
        data[i].sortingDate = new Date(data[i].dDateCreated);
        this.inProgressTokens.push(data[i]);
      }
      if (data[i]['completedId'] != null) {
        data[i].sortingDate = new Date(data[i].completeDate);
        this.completedTokens.push(data[i]);
      }
    }

    this.newTokens.sort((a,b) => b.sortingDate.getTime() - a.sortingDate.getTime()); 
    this.inProgressTokens.sort((a,b) => b.sortingDate.getTime() - a.sortingDate.getTime()); 
    this.completedTokens.sort((a,b) => b.sortingDate.getTime() - a.sortingDate.getTime()); 
    this.bShowLoader = false;

  }

  startAssessment(token: string): void {
   this.router.navigate(['funCog/'+token]);
  }

  viewAssessment(token: string): void {
    this.router.navigate(['report/'+token]);
  }

  addTokenToList(token: string): void {
    this.assessmentService.addUserToken(token, this.assessmentType).subscribe(
      data => {
        this.getUserTokens();
      },
      err => {
        this.errorMessage = err.error.message;
      }
    );
  }

  generateToken(): void {
    this.sToken = this.NEW_GUID;
  }

  public get NEW_GUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        // tslint:disable-next-line:one-variable-per-declaration
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
  }

  copyClientUrl(token: string): void {
    this.clipboardApi.copyFromContent(this.clientUrl + '/' + token);
  }

  copyReportUrl(token: string): void {
    this.clipboardApi.copyFromContent(this.reportUrl + '/' + token);
  }

  copyTherapistUrl(): void {
    this.clipboardApi.copyFromContent(this.therapistUrl + '/t-' + this.sToken);
  }

  allocateName(tokenItem: iTokenItem): void {

    this.assessmentService.allocateNameToToken(tokenItem.token, tokenItem.participantName).subscribe(
      data => {
        console.log('name saved', data);
      },
      err => {
        this.errorMessage = err.error.message;
      }
    );
    tokenItem.showAllocateBox = !tokenItem.showAllocateBox;
  }

}
