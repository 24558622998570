import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class ApiTherapistService {

    logoUrl = './assets/images/logo/GOINGPLACES_RGB no tag.jpg';
    logoSmallUrl = './assets/images/logo/GOINGPLACES_RGB no words.jpg';

    countryName = '';
    regionName = '';

    surveyResults = {};
    surveyPages = [];
    surveyTime = 0;

    constructor(private router: Router) {
    }

    redirectToComplete(surveyResults: object, surveyPages: [], surveyTime: number): void {

        this.surveyResults = surveyResults;
        this.surveyPages = surveyPages;
        this.surveyTime = surveyTime;
        this.router.navigate(['tcomplete']);
    }

    getNewAssessment(token: string): any {
        const surveyJSON =  {
            title: '',
            description: '',
            logo: this.logoUrl,
            logoPosition: 'right',
            logoHeight: 80,
            showQuestionNumbers: 'off',
            showPageNumbers: false,
            goNextPageAutomatic: false,
            lazyRowsRendering: true,
            completedHtml: '',
            // loadingHtml: 'Please wait while the assessment is loading.',
            cookieName: token,
            pages: [
                {
                    name: 'page1',
                    title: ' ',
                    elements: this.getPage1()
                },
                {
                    name: 'page2',
                    title: 'TEST FEEDBACK',
                    elements: this.getPage2()
                },
            ]
        };

        return surveyJSON;
    }

    getPage1(): any {
        const elements = [
            {
                type: 'html',
                name: 'q_demographics',
                html: '<H2 style="color: #1ab394;font-weight: bold;margin-top: -6px;">OCCUPATIONAL THERAPIST FEEDBACK</H2><br><div class="page-title">DEMOGRAPHICS</div>'
            },
            {
                type: 'text',
                title: 'Therapist: Type in number',
                name: 'q_pseudonym'
            },
            {
                type: 'comment',
                title: 'Client diagnosis',
                name: 'q_diagnosis'
            },
            {
                type: 'html',
                name: 'q_obverations',
                html: '<br><h4><span><b>Clinical observations</b></span></h4>'
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'q_orientation',
                title: 'Orientation to place',
                isRequired: false,
                maxWidth: '300px',
                minWidth: '300px',
                choices: [{value: 'Poor', text: 'Poor'},
                          {value: 'Fair', text: 'Fair'},
                          {value: 'Good', text: 'Good '}]
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'q_approach',
                title: 'Approach',
                startWithNewLine: false,
                isRequired: false,
                maxWidth: '300px',
                minWidth: '300px',
                choices: [{value: 'Unstructured', text: 'Unstructured'},
                          {value: 'Fluctuating', text: 'Fluctuating'},
                          {value: 'Structured', text: 'Structured '}]
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'q_mood',
                title: 'Mood',
                startWithNewLine: false,
                isRequired: false,
                maxWidth: '280px',
                minWidth: '280px',
                choices: [{value: 'Anxious', text: 'Anxious'},
                          {value: 'Fluctuating', text: 'Fluctuating'},
                          {value: 'Calm', text: 'Calm '}]
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'q_concentration',
                title: 'Concentration',
                startWithNewLine: true,
                isRequired: false,
                maxWidth: '300px',
                minWidth: '300px',
                choices: [{value: 'Poor', text: 'Poor'},
                          {value: 'Fluctuating', text: 'Fluctuating'},
                          {value: 'Good', text: 'Good '}]
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'q_processingspeed',
                title: 'Processing speed',
                isRequired: false,
                startWithNewLine: false,
                maxWidth: '300px',
                minWidth: '300px',
                choices: [{value: 'Slow', text: 'Slow'},
                          {value: 'Average', text: 'Average'},
                          {value: 'Fast ', text: 'Fast'}]
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'q_insight',
                title: 'Insight',
                startWithNewLine: false,
                isRequired: false,
                maxWidth: '280px',
                minWidth: '280px',
                choices: [{value: 'Poor', text: 'Poor'},
                          {value: 'Questionable', text: 'Questionable'},
                          {value: 'Good ', text: 'Good'}]
            },
            {
                type: 'html',
                name: 'q_obverationscomments',
                html: '<br><h4><span><b>Clinical observation comments</b></span></h4>'
            },
            {
                type: 'comment',
                title: 'Comment on any other clinical observations you think are relevant.',
                name: 'q_clinicalobservation'
            },
            {
                type: 'html',
                name: 'q_otherscores',
                html: '<br><h4><span><b>Other test scores</b></span></h4>'
            },
            {
                type: 'text',
                title: 'MOCA Total/30',
                isRequired: false,
                maxWidth: '300px',
                minWidth: '300px',
                name: 'q_mocatotal',
                placeHolder: ''
            },
            {
                type: 'text',
                title: 'Visuospatial/Executive /5',
                isRequired: false,
                maxWidth: '300px',
                minWidth: '300px',
                name: 'q_moca_visuospatial',
                placeHolder: '',
                startWithNewLine: false
            },
            {
                type: 'text',
                title: 'Naming /3',
                isRequired: false,
                maxWidth: '280px',
                minWidth: '280px',
                name: 'q_moca_naming',
                placeHolder: '',
                startWithNewLine: false
            },
            {
                type: 'text',
                title: 'Attention: Digits /2',
                isRequired: false,
                maxWidth: '300px',
                minWidth: '300px',
                name: 'q_moca_attention_digits',
                placeHolder: '',
            },
            {
                type: 'text',
                title: 'Attention: Tapping /1',
                isRequired: false,
                maxWidth: '300px',
                minWidth: '300px',
                name: 'q_moca_attention_tapping',
                placeHolder: '',
                startWithNewLine: false
            },
            {
                type: 'text',
                title: 'Attention: Serial 7 /3',
                isRequired: false,
                maxWidth: '280px',
                minWidth: '280px',
                name: 'q_moca_attention_serial',
                placeHolder: '',
                startWithNewLine: false
            },
            {
                type: 'text',
                title: 'Language: Sentences /2',
                isRequired: false,
                maxWidth: '300px',
                minWidth: '300px',
                name: 'q_moca_language_sentences',
                placeHolder: '',
            },
            {
                type: 'text',
                title: 'Language: Fluency /1',
                isRequired: false,
                maxWidth: '300px',
                minWidth: '300px',
                name: 'q_moca_language_fluency',
                placeHolder: '',
                startWithNewLine: false
            },
            {
                type: 'text',
                title: 'Abstraction /2',
                isRequired: false,
                maxWidth: '280px',
                minWidth: '280px',
                name: 'q_moca_abstraction',
                placeHolder: '',
                startWithNewLine: false
            },
            {
                type: 'text',
                title: 'Delayed recall /5',
                isRequired: false,
                maxWidth: '300px',
                minWidth: '300px',
                name: 'q_moca_delayed_recall',
                placeHolder: '',
            },
            {
                type: 'text',
                title: 'Orientation /6',
                isRequired: false,
                maxWidth: '280px',
                minWidth: '280px',
                name: 'q_moca_orientation',
                placeHolder: '',
                startWithNewLine: false
            },
            {
                type: 'text',
                startWithNewLine: true,
                isRequired: false,
                maxWidth: '300px',
                minWidth: '300px',
                title: 'Maze: Time in seconds',
                name: 'q_mazetimeinseconds'
            },
            {
                type: 'text',
                startWithNewLine: false,
                isRequired: false,
                maxWidth: '280px',
                minWidth: '280px',
                title: 'Maze: Number of mistakes',
                name: 'q_mazenumberofmistakes'
            },
            {
                type: 'text',
                title: 'Bells: Time in seconds',
                isRequired: false,
                maxWidth: '300px',
                minWidth: '300px',
                name: 'q_bellstimeinseconds'
            },
            {
                type: 'text',
                startWithNewLine: false,
                isRequired: false,
                maxWidth: '280px',
                minWidth: '280px',
                title: 'Bells: Number of mistakes',
                name: 'q_bellsnumberofmistakes'
            },
            {
                type: 'text',
                title: 'Trails B: Time in seconds',
                isRequired: false,
                maxWidth: '300px',
                minWidth: '300px',
                name: 'q_trailsbtimeinseconds'
            },
            {
                type: 'text',
                startWithNewLine: false,
                isRequired: false,
                maxWidth: '280px',
                minWidth: '280px',
                title: 'Trails B: Number of mistakes',
                name: 'q_trailsnumberofmistakes'
            },
            {
                type: 'html',
                name: 'q_fitness1',
                html: '<br><h4><span><b>Fitness-to-drive assessment outcome</b><br><br>Once the client has completed the entire fitness to drive assessment what was the outcome of the assessment?</span></h4>'
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'q_fitness2',
                title: 'Select the descriptor which best matches the assessment outcome.',
                isRequired: false,
                choices: [{value: 'Failed clinical assessment due to cognitive impairment. (This can include other impairments however cognition needs to be a contributor to the failure.) Not fit to drive.',
                           text: 'Failed clinical assessment due to cognitive impairment. (This can include other impairments however cognition needs to be a contributor to the failure.) Not fit to drive.'},
                          {value: 'Failed clinical assessment, however cognition was not a contributing factor. Not fit to drive.',
                           text: 'Failed clinical assessment, however cognition was not a contributing factor. Not fit to drive.'},
                          {value: 'Failed on-road assessment due to cognitive impairment. (This can include other impairments however cognition needs to be a contributor to the failure.) Not fit to drive.',
                           text: 'Failed on-road assessment due to cognitive impairment. (This can include other impairments however cognition needs to be a contributor to the failure.) Not fit to drive.'},
                          {value: 'Failed on-road assessment, however cognition was not a contributing factor. Not fit to drive.',
                           text: 'Failed on-road assessment, however cognition was not a contributing factor. Not fit to drive.'},
                          {value: 'Passed on-road assessment. Fit to drive.', text: 'Passed on-road assessment. Fit to drive.'}]
            }

        ];

        return elements;
    }

    getPage2(): any {
        const elements = [
            {
                type: 'html',
                name: 'q_testadmin',
                html: '<h4><span><b>Test adminsitration</b></span></h4>'
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'q_readinstructions1',
                title: 'Was the client able to read the instructions independently?',
                isRequired: false,
                choices: [{value: 'Yes', text: 'Yes'}, {value: 'No', text: 'No'}]
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'q_readinstructions2',
                title: 'Did you read the instructions to the client?',
                isRequired: false,
                choices: [{value: 'Yes', text: 'Yes'}, {value: 'No', text: 'No'}]
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'q_operatedevice',
                title: 'Was the client able to operate the electronic device functions independently?',
                isRequired: false,
                choices: [{value: 'Yes', text: 'Yes'}, {value: 'No', text: 'No'}]
            },
            {
                type: 'html',
                name: 'q_testitems',
                html: '<br><h4><span><b>Test items</b></span></h4>'
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'q_understandquestions',
                title: 'Was the client able to understand the questions and instructions?',
                isRequired: false,
                choices: [{value: 'None', text: 'None'}, {value: 'Some', text: 'Some'}, {value: 'All', text: 'All'}]
            },
            {
                type: 'html',
                name: 'q_struggle1',
                html: '<br><h4><span>If they were not able to understand the questions:</span></h4>'
            },
            {
                type: 'comment',
                title: 'Which questions did they struggle with?',
                name: 'q_struggle2'
            },
            {
                type: 'comment',
                title: 'How can the question be improved to enhance the client’s understanding?',
                name: 'q_questionimprove'
            },
            {
                type: 'html',
                name: 'q_otherfeedback',
                html: '<br><h4><span><b>Other feedback you think may be helpful.</b></span></h4>'
            },
            {
                type: 'comment',
                title: ' ',
                name: 'q_otherfeedbackcomment'
            },
        ];

        return elements;
    }


}
