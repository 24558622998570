<app-header [showLogout]="false" [showTitle]="false" [showBack]="showBack"></app-header>
<div style="float: right;padding-top: 8px;padding-right: 8px;">
  Choose a language
  <select class="form-select form-select-sm" style="font-size: 18px;" [(ngModel)]="assessmentLanguage" (ngModelChange)="switchLanguage()">
    <option value="English">English</option>
    <option value="Afrikaans">Afrikaans</option>
    <option value="Xhosa">Xhosa</option>
    <option value="Zulu">Zulu</option>
  </select>
</div>
<div id="surveyElement" style="display:inline-block;width:100%;margin: 10px auto 32px;"></div>
<div id="surveyResult">{{surveyResult}}</div>
<!-- <div class="footer">
  <p>&copy; Copyright 2021</p>
</div> -->