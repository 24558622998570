import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constants';

// new assessment
import * as codris_en from '../assets/data/codris_en.json';
import * as codris_employee_en from '../assets/data/codris_employee_en.json';
import * as codris_af from '../assets/data/codris_af.json';
import * as codris_employee_af from '../assets/data/codris_employee_af.json';
import * as codris_zu from '../assets/data/codris_zu.json';
import * as codris_employee_zu from '../assets/data/codris_employee_zu.json';
import * as codris_xh from '../assets/data/codris_xh.json';
import * as codris_employee_xh from '../assets/data/codris_employee_xh.json';

// REPORTS
// english
import * as codris_report_en from '../assets/data/codris_report_en.json';
import * as codris_employee_report_en from '../assets/data/codris_employee_report_en.json';
import * as report_insight_interpretation_en from '../assets/data/report_insight_interpretation_en.json';
import * as report_general_en from '../assets/data/report_general_en.json';
// afrikaans
import * as codris_report_af from '../assets/data/codris_report_af.json';
import * as codris_employee_report_af from '../assets/data/codris_employee_report_af.json';
import * as report_general_af from '../assets/data/report_general_af.json';
import * as report_insight_interpretation_af from '../assets/data/report_insight_interpretation_af.json';
// zulu
import * as codris_report_zu from '../assets/data/codris_report_zu.json';
import * as codris_employee_report_zu from '../assets/data/codris_employee_report_zu.json';
import * as report_general_zu from '../assets/data/report_general_zu.json';
import * as report_insight_interpretation_zu from '../assets/data/report_insight_interpretation_zu.json';
// xhosa
import * as codris_report_xh from '../assets/data/codris_report_xh.json';
import * as codris_employee_report_xh from '../assets/data/codris_employee_report_xh.json';
import * as report_general_xh from '../assets/data/report_general_xh.json';
import * as report_insight_interpretation_xh from '../assets/data/report_insight_interpretation_xh.json';

@Injectable({
    providedIn: 'root'
})

export class ApiService {

    surveyResults = {};
    surveyPages = [];
    surveyTime = 0;

    countryName = '';
    regionName = '';

    constructor(private router: Router, public constants: AppConstants) {
    }

    redirectToComplete(surveyResults: object, surveyPages: [], surveyTime: number, token: string): void {

        this.surveyResults = surveyResults;
        this.surveyPages = surveyPages;
        this.surveyTime = surveyTime;
        this.router.navigate(['complete/' + token], {
            queryParams: {refresh: new Date().getTime()}
         });
    }

     getNewAssessment(token: string, surveyLanguage: string, surveyType: string): any {

        if (surveyType === this.constants.CODRIS) {
            if (surveyLanguage === this.constants.ENGLISH) {
                var surveyJSON: any = codris_en.assessment ;
            } else if (surveyLanguage === this.constants.AFRIKAANS) {
                var surveyJSON: any = codris_af.assessment ;
            } else if (surveyLanguage === this.constants.ZULU) {
                var surveyJSON: any = codris_zu.assessment ;
            } else if (surveyLanguage === this.constants.XHOSA) {
                var surveyJSON: any = codris_xh.assessment ;
            }
        } else {
            if (surveyLanguage === this.constants.ENGLISH) {
                var surveyJSON: any = codris_employee_en.assessment;
            } else if (surveyLanguage === this.constants.AFRIKAANS) {
                var surveyJSON: any = codris_employee_af.assessment;
            } else if (surveyLanguage === this.constants.ZULU) {
                var surveyJSON: any = codris_employee_zu.assessment;
            } else if (surveyLanguage === this.constants.XHOSA) {
                var surveyJSON: any = codris_employee_xh.assessment;
            }
        }
        surveyJSON.cookieName = token;
        return surveyJSON;
    }

    getReportAssessment(surveyLanguage: string, surveyType: string) {
        
        if (surveyType === this.constants.CODRIS) {
            if (surveyLanguage === this.constants.ENGLISH) {
                var reportJSON: any = codris_report_en.report ;
            } else if (surveyLanguage === this.constants.AFRIKAANS) {
                var reportJSON: any = codris_report_af.report ;
            } else if (surveyLanguage === this.constants.ZULU) {
                var reportJSON: any = codris_report_zu.report ;
            } else if (surveyLanguage === this.constants.XHOSA) {
                var reportJSON: any = codris_report_xh.report ;
            }
        } else {
            if (surveyLanguage === this.constants.ENGLISH) {
                var reportJSON: any = codris_employee_report_en.report ;
            } else if (surveyLanguage === this.constants.AFRIKAANS) {
                var reportJSON: any = codris_employee_report_af.report ;
            } else if (surveyLanguage === this.constants.ZULU) {
                var reportJSON: any = codris_employee_report_zu.report ;
            } else if (surveyLanguage === this.constants.XHOSA) {
                var reportJSON: any = codris_employee_report_xh.report ;
            }
        }
        return reportJSON;
    }

    getReportInsightInterpretation(surveyLanguage: string) {
        if (surveyLanguage === this.constants.ENGLISH) {
            var reportJSON: any = report_insight_interpretation_en.report ;
        } else if (surveyLanguage === this.constants.AFRIKAANS) {
            var reportJSON: any = report_insight_interpretation_af.report;
        } else if (surveyLanguage === this.constants.ZULU) {
            var reportJSON: any = report_insight_interpretation_zu.report;
        } else if (surveyLanguage === this.constants.XHOSA) {
            var reportJSON: any = report_insight_interpretation_xh.report;
        }
        return reportJSON;
    }

    getReportGeneralInformation(surveyLanguage: string) {
        if (surveyLanguage === this.constants.ENGLISH) {
            var reportJSON: any = report_general_en.report;
        } else if (surveyLanguage === this.constants.AFRIKAANS) {
            var reportJSON: any = report_general_af.report;
        } else if (surveyLanguage === this.constants.ZULU) {
            var reportJSON: any = report_general_zu.report;
        } else if (surveyLanguage === this.constants.XHOSA) {
            var reportJSON: any = report_general_xh.report;
        }
        return reportJSON;
    }
}
