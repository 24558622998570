import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-land-mark-svg3',
  templateUrl: './land-mark-svg3-english.component.svg',
  styleUrls: []
})
export class LandMarkSvg3EnglishComponent implements OnInit, OnDestroy {

  shop1 = 'transparent';
  restaurant1 = 'transparent';
  postOffice = 'transparent';
  blockA4 = 'transparent';
  blockA5 = 'transparent';

  blockB1 = 'transparent';
  church = 'transparent';
  school = 'transparent';
  sportsField = 'transparent';
  restaurant4 = 'transparent';

  blockC1 = 'transparent';
  blockC2 = 'transparent';
  fuelStation = 'transparent';
  blockC4 = 'transparent';
  fireStation = 'transparent';

  blockD1 = 'transparent';
  blockD2 = 'transparent';
  blockD3 = 'transparent';
  blockD4 = 'transparent';
  blockD5 = 'transparent';

  hairDresser = 'transparent';
  shop3 = 'transparent';
  blockE3 = 'transparent';
  restaurant3 = 'transparent';

  hospital = 'transparent';
  blockF2 = 'transparent';
  policeStation = 'transparent';
  blockF4 = 'transparent';

  shop2 = 'transparent';
  restaurant2 = 'transparent';
  blockG3 = 'transparent';
  shop4 = 'transparent';

  park = 'transparent';

  constructor(){
  }

  ngOnInit(): void {
    localStorage.setItem('LANDMARKq3', '');
  }

  ngOnDestroy(): void {
  }

  changeColor(sName: string): void {

    const imageColor = '#1ab394';
    // const imageColor = 'red';
    localStorage.setItem('LANDMARKq3', sName);

    if (sName == 'shop1' && this.shop1 == imageColor) {
      this.shop1 = 'transparent';
    } else if (sName == 'shop1') {
      this.shop1 = imageColor; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'restaurant1' && this.restaurant1 == imageColor) {
      this.restaurant1 = 'transparent';
    } else if (sName == 'restaurant1') {
      this.shop1 = 'transparent'; this.restaurant1 = imageColor; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'postOffice' && this.postOffice == imageColor) {
      this.postOffice = 'transparent';
    } else if (sName == 'postOffice'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = imageColor; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'blockA4' && this.blockA4 == imageColor) {
      this.blockA4 = 'transparent';
    } else if (sName == 'blockA4'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = imageColor;
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'blockA5' && this.blockA5 == imageColor) {
      this.blockA5 = 'transparent';
    } else if (sName == 'blockA5'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = imageColor; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'blockB1' && this.blockB1 == imageColor) {
      this.blockB1 = 'transparent';
    } else if (sName == 'blockB1'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = imageColor; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'church' && this.church == imageColor) {
      this.church = 'transparent';
    } else if (sName == 'church'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = imageColor; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }
    
    if (sName == 'school' && this.school == imageColor) {
      this.school = 'transparent';
    } else if (sName == 'school'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = imageColor;
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }
    
    if (sName == 'sportsField' && this.sportsField == imageColor) {
      this.sportsField = 'transparent';
    } else if (sName == 'sportsField'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = imageColor; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'restaurant4' && this.restaurant4 == imageColor) {
      this.restaurant4 = 'transparent';
    } else if (sName == 'restaurant4'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = imageColor; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'blockC1' && this.blockC1 == imageColor) {
      this.blockC1 = 'transparent';
    } else if (sName == 'blockC1'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = imageColor; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'blockC2' && this.blockC2 == imageColor) {
      this.blockC2 = 'transparent';
    } else if (sName == 'blockC2'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = imageColor;
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'fuelStation' && this.fuelStation == imageColor) {
      this.fuelStation = 'transparent';
    } else if (sName == 'fuelStation'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = imageColor; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'blockC4' && this.blockC4 == imageColor) {
      this.blockC4 = 'transparent';
    } else if (sName == 'blockC4'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = imageColor; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'fireStation' && this.fireStation == imageColor) {
      this.fireStation = 'transparent';
    } else if (sName == 'fireStation'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = imageColor; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'blockD1' && this.blockD1 == imageColor) {
      this.blockD1 = 'transparent';
    } else if (sName == 'blockD1'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = imageColor;
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'blockD2' && this.blockD2 == imageColor) {
      this.blockD2 = 'transparent';
    } else if (sName == 'blockD2'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = imageColor; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'blockD3' && this.blockD3 == imageColor) {
      this.blockD3 = 'transparent';
    } else if (sName == 'blockD3'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = imageColor; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'blockD4' && this.blockD4 == imageColor) {
      this.blockD4 = 'transparent';
    } else if (sName == 'blockD4'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = imageColor; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'blockD5' && this.blockD5 == imageColor) {
      this.blockD5 = 'transparent';
    } else if (sName == 'blockD5'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = imageColor;
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'hairDresser' && this.hairDresser == imageColor) {
      this.hairDresser = 'transparent';
    } else if (sName == 'hairDresser'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = imageColor; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'shop3' && this.shop3 == imageColor) {
      this.shop3 = 'transparent';
    } else if (sName == 'shop3'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = imageColor; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'blockE3' && this.blockE3 == imageColor) {
      this.blockE3 = 'transparent';
    } else if (sName == 'blockE3'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = imageColor; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'restaurant3' && this.restaurant3 == imageColor) {
      this.restaurant3 = 'transparent';
    } else if (sName == 'restaurant3'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = imageColor;
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'hospital' && this.hospital == imageColor) {
      this.hospital = 'transparent';
    } else if (sName == 'hospital'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = imageColor; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'blockF2' && this.blockF2 == imageColor) {
      this.blockF2 = 'transparent';
    } else if (sName == 'blockF2'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = imageColor; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'policeStation' && this.policeStation == imageColor) {
      this.policeStation = 'transparent';
    } else if (sName == 'policeStation'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = imageColor; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'blockF4' && this.blockF4 == imageColor) {
      this.blockF4 = 'transparent';
    } else if (sName == 'blockF4'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = imageColor;
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }
    
    if (sName == 'shop2' && this.shop2 == imageColor) {
      this.shop2 = 'transparent';
    } else if (sName == 'shop2'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = imageColor; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'restaurant2' && this.restaurant2 == imageColor) {
      this.restaurant2 = 'transparent';
    } else if (sName == 'restaurant2'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = imageColor; this.blockG3 = 'transparent'; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'blockG3' && this.blockG3 == imageColor) {
      this.blockG3 = 'transparent';
    } else if (sName == 'blockG3'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = imageColor; this.shop4 = 'transparent'; this.park = 'transparent';
    }

    if (sName == 'shop4' && this.shop4 == imageColor) {
      this.shop4 = 'transparent';
    } else if (sName == 'shop4'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = imageColor; this.park = 'transparent';
    }

    if (sName == 'park' && this.park == imageColor) {
      this.park = 'transparent';
    } else if (sName == 'park'){
      this.shop1 = 'transparent'; this.restaurant1 = 'transparent'; this.postOffice = 'transparent'; this.blockA4 = 'transparent';
      this.blockA5 = 'transparent'; this.blockB1 = 'transparent'; this.church = 'transparent'; this.school = 'transparent';
      this.sportsField = 'transparent'; this.restaurant4 = 'transparent'; this.blockC1 = 'transparent'; this.blockC2 = 'transparent';
      this.fuelStation = 'transparent'; this.blockC4 = 'transparent'; this.fireStation = 'transparent'; this.blockD1 = 'transparent';
      this.blockD2 = 'transparent'; this.blockD3 = 'transparent'; this.blockD4 = 'transparent'; this.blockD5 = 'transparent';
      this.hairDresser = 'transparent'; this.shop3 = 'transparent'; this.blockE3 = 'transparent'; this.restaurant3 = 'transparent';
      this.hospital = 'transparent'; this.blockF2 = 'transparent'; this.policeStation = 'transparent'; this.blockF4 = 'transparent';
      this.shop2 = 'transparent'; this.restaurant2 = 'transparent'; this.blockG3 = 'transparent'; this.shop4 = 'transparent';
      this.park = imageColor;
    }

  }

}
